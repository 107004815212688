import React from "react";
import { AiFillStar, AiFillHeart } from "react-icons/ai";
import { HiOutlineClock } from "react-icons/hi";
import restaurantImage from "../assets/restaurant.png";

const restaurantData = [
  {
    id: 1,
    name: "Thai Famous Cuisine",
    cuisines: "North Indian . Indian . Pure Veg",
    deliveryTime: "30 min",
    price: "12.50",
    rating: 4.5,
    totalRatings: 500,
    image: restaurantImage 
  },
  {
    id: 1,
    name: "Thai Famous Cuisine",
    cuisines: "North Indian . Indian . Pure Veg",
    deliveryTime: "30 min",
    price: "12.50",
    rating: 4.5,
    totalRatings: 500,
    image: restaurantImage 
  },
  {
    id: 1,
    name: "Thai Famous Cuisine",
    cuisines: "North Indian . Indian . Pure Veg",
    deliveryTime: "30 min",
    price: "12.50",
    rating: 4.5,
    totalRatings: 500,
    image: restaurantImage 
  },
  {
    id: 1,
    name: "Thai Famous Cuisine",
    cuisines: "North Indian . Indian . Pure Veg",
    deliveryTime: "30 min",
    price: "12.50",
    rating: 4.5,
    totalRatings: 500,
    image: restaurantImage 
  },
  {
    id: 1,
    name: "Thai Famous Cuisine",
    cuisines: "North Indian . Indian . Pure Veg",
    deliveryTime: "30 min",
    price: "12.50",
    rating: 4.5,
    totalRatings: 500,
    image: restaurantImage 
  },
  {
    id: 1,
    name: "Thai Famous Cuisine",
    cuisines: "North Indian . Indian . Pure Veg",
    deliveryTime: "30 min",
    price: "12.50",
    rating: 4.5,
    totalRatings: 500,
    image: restaurantImage 
  },
  {
    id: 1,
    name: "Thai Famous Cuisine",
    cuisines: "North Indian . Indian . Pure Veg",
    deliveryTime: "30 min",
    price: "12.50",
    rating: 4.5,
    totalRatings: 500,
    image: restaurantImage 
  },
  {
    id: 1,
    name: "Thai Famous Cuisine",
    cuisines: "North Indian . Indian . Pure Veg",
    deliveryTime: "30 min",
    price: "12.50",
    rating: 4.5,
    totalRatings: 500,
    image: restaurantImage 
  },
];

export function NearRestaurantCard() {
  return (
    <div className="py-20">
    <div className="flex items-start justify-start mx-[100px] mb-5">
      <h3 className="text-2xl font-medium">Nearby Restaurants</h3>
    </div>
    <div className="grid grid-cols-1 md:grid-cols-4 w-[85%] gap-8 mx-auto mb-10">
      {restaurantData.map((restaurant) => (
        <div key={restaurant.id} className="p-0 max-w-[250px] max-h-[300px] rounded-md justify-items-center bg-white restaurant-card-shadow  ">
          <div className="rest-card-img rounded-tl-md rounded-tr-md overflow-hidden max-h-[9.4rem] static">
            <img
              height="150px"
              className="object-cover flex flex-row justify-center h-[160px] w-[100%]"
              src={restaurant.image}
              alt={restaurant.name}
            />
            <div className="flex w-[100%] pr-3.5 justify-end relative top-[-150px]">
              <div className="rounded-full p-1 bg-primary text-white ">
                <AiFillHeart
                  size={15}
                  onClick={() => {}}
                  style={{  }}
                />
              </div>
            </div>
            <div className="flex w-[100%] pr-4 justify-end relative top-[-60px] text-[12px] font-[Gilroy] text-white">
              <div className="flex flex-row rounded-md bg-primary w-[80px] h-[20px] justify-center items-center">
                <AiFillStar color={"white"} size={15} /> &nbsp;
                <p>{restaurant.rating} ({restaurant.totalRatings})</p>
              </div>
            </div>
          </div>
          <div className="rest-card-content min-[225px] w-[100%] p-[0.6rem] 3xl:pt-[0.5rem] 2xl:pt-[1rem] xl:pt-[0.4rem] lg:pt-[0.7rem] md2:pt-[0.25rem] mr-[1px] border border-1 border-t-0 border-primary rounded-bl-md rounded-br-md 2xl:max-h-[150px] xl:max-h-[130px] lg:max-h-[125px] md2:max-h-[115px] md:max-h-[120px] sm:max-h-[130px] xs:max-h-[130px] 1xl:max-h-[125px]">
            <div className="text-left 2xl:text-[18px] xl:text-[18px] 1xl:text-[17px] lg:text-[18px] md:text-[16px] sm:text-[18px] xs:text-[18px] font-[Gilroy-Semibold] text-primary max-w-[100%] grow-0 truncate">{restaurant.name}</div>
            <div className="text-left font-[Gilroy] text-grey sm:text-[13px] xs:text-[13px] md:text-[11px] lg:text-[11px] 1xl:text-[10px] xl:text-[14px] 2xl:text-[14px] max-w-[100%] grow-0 truncate">{restaurant.cuisines}</div>
            <div className="flex flex-row gap-3 mt-1 justify-between items-center ">
              <div className="text-[12px] font-[Gilroy-Semibold] bg-primary text-white rounded-md px-3">offer</div>
              <div className="text-[20px] font-[Gilroy-Semibold] text-primary">65% Off</div>
            </div>
          </div>
        </div>
      ))}
      </div>
    </div>
  );
}
