import { BaseModal } from "./BaseModal";
import { ErrorMessage, Field, FormikProvider, useFormik } from "formik";
import { useState } from "react";
import { addItemToCart } from "../api/cart/cart";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineGoogle } from "react-icons/ai";
import { FaFacebook } from "react-icons/fa";
import loginbackground from "../assets/login.png";
import { postRegisterUser } from "../api/auth/auth";
import * as Yup from "yup";
import set = Reflect.set;
import { ImSpinner3 } from "react-icons/im";

export const SignupForm = () => {
  const navigate = useNavigate();
  const [err, setErr] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [apiLoading, setApiLoading] = useState<boolean>(false);

  const signupSchema = Yup.object().shape({
    first_name: Yup.string().required("Required").min(3, "Too Short!"),
    last_name: Yup.string().required("Required").min(1, "Too Short!"),
    email: Yup.string().email().required("Invalid input entered"),
    phone_number: Yup.string()
      .required("Required")
      .min(9, "Enter a valid phone number"),
    // password: Yup.string().required('Required').min(8, 'Too Short!'),
    password: Yup.string()
      .required("Required")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password must contain at least 8 characters, one letter, one number, and one special character"
      ),
    confirm_password: Yup.string()
      .required("Required")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password must contain at least 8 characters, one letter, one number, and one special character"
      ),
  });

  const handleSignup = async (payload: any) => {
    setApiLoading(true);
    const res = await postRegisterUser(payload);
    console.log(res);
    if (res?.request?.status !== 200) {
      setApiLoading(false);
      setErr(res?.response?.data.message);
      setTimeout(() => {
        setErr(null);
      }, 2000);
    } else {
      setApiLoading(false);
      navigate("/login");
    }
  };

  const formik = useFormik({
    initialValues: {},
    validationSchema: signupSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify({...values, user_login_status: "customer" }, null, 2));
      handleSignup({ ...values, user_login_status: "customer" });
    },
  });

  return (
    <BaseModal auth={true}>
      <div className="font-[Gilroy-Semibold] text-[40px]">Sign Up</div>
      <div className="font-[Gilroy] text-[14px] text-sec-grey">
        <span>Already have an Account?</span>
        &nbsp; &nbsp;
        <span className="font-[Gilroy-Semibold] text-primary">
          <Link to="/login">Login</Link>
        </span>
      </div>

      <div className="mt-5 mb-5">
        <hr className="w-[100%] h-[0.1px] bg-grey border-0 rounded bg-grey" />
      </div>

      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          {page === 0 ? (
            <>
              <div className="flex flex-col items-left text-left font-[Gilroy] text-[14px] text-black">
                <label htmlFor="first_name">First Name</label>
                <Field
                  className="font-[Gilroy] text-[14px] text-sec-grey h-[35px] outline-none focus:outline-none border-b border-underline-c"
                  id="first_name"
                  name="first_name"
                  placeholder="Ex. John"
                />
                <div className="font-[Gilroy] text-[10px] text-primary pb-2">
                  <ErrorMessage
                    name="first_name"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
              <div className="flex flex-col items-left text-left font-[Gilroy] text-[14px] text-black">
                <label htmlFor="last_name">Last Name</label>
                <Field
                  className="font-[Gilroy] text-[14px] text-sec-grey h-[35px] outline-none focus:outline-none border-b border-underline-c"
                  id="last_name"
                  name="last_name"
                  placeholder="Ex. Doe"
                />
                <div className="font-[Gilroy] text-[10px] text-primary pb-2">
                  <ErrorMessage
                    name="last_name"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
            </>
          ) : null}

          {page === 1 ? (
            <>
              <div className="flex flex-col items-left text-left font-[Gilroy] text-[14px] text-black">
                <label htmlFor="email">Email</label>
                <Field
                  className="font-[Gilroy] text-[14px] text-sec-grey h-[35px] outline-none focus:outline-none border-b border-underline-c"
                  id="email"
                  name="email"
                  placeholder="Enter your Email"
                  type="email"
                />
                <div className="font-[Gilroy] text-[10px] text-primary pb-2">
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              <div className="flex flex-col items-left text-left font-[Gilroy] text-[14px] text-black">
                <label htmlFor="phone_number">Phone</label>
                <Field
                  className="font-[Gilroy] text-[14px] text-sec-grey h-[35px] outline-none focus:outline-none border-b border-underline-c"
                  id="phone_number"
                  name="phone_number"
                  placeholder="Enter your Phone Number"
                />
                <div className="font-[Gilroy] text-[10px] text-primary pb-2">
                  <ErrorMessage
                    name="phone_number"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              <div className="flex flex-col items-left text-left font-[Gilroy] text-[14px] text-black">
                <label htmlFor="password">Password</label>
                <Field
                  className="font-[Gilroy] text-[14px] text-sec-grey h-[35px] outline-none focus:outline-none border-b border-underline-c"
                  id="password"
                  name="password"
                  placeholder="min. 8 characters"
                  type="password"
                />
                <div className="font-[Gilroy] text-[10px] text-primary pb-2">
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              <div className="flex flex-col items-left text-left font-[Gilroy] text-[14px] text-black">
                <label htmlFor="confirm_password">Confirm Password</label>
                <Field
                  className="font-[Gilroy] text-[14px] text-sec-grey h-[35px] outline-none focus:outline-none border-b border-underline-c"
                  id="confirm_password"
                  name="confirm_password"
                  placeholder="min. 8 characters"
                  type="password"
                />
                <div className="font-[Gilroy] text-[10px] text-primary pb-2">
                  <ErrorMessage
                    name="confirm_password"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
            </>
          ) : null}

          <div className="mb-4">
            {err ? (
              <div className="font-[Gilroy] text-[16px] text-left text-primary p-2 w-[100%] bg-primary bg-opacity-20 ">
                {err}
              </div>
            ) : apiLoading ? (
              <div className="grid grid-cols-1 mt-5 justify-items-center">
                <ImSpinner3 size={20} color="light" />
              </div>
            ) : (
              <div className="flex flex-row gap-2">
                {page === 1 ? (
                  <button
                    className="w-[100%] bg-primary text-white font-[Gilroy] rounded-full h-[40px] mt-4"
                    type={`${page === 1 ? "submit" : "button"}`}
                    onClick={() => setPage(0)}
                  >
                    Back
                  </button>
                ) : null}

                <button
                  className="w-[100%] bg-primary text-white font-[Gilroy] rounded-full h-[40px] mt-4"
                  type={`${page == 1 ? "submit" : "button"}`}
                  onClick={() => {
                    if (
                      page == 0 &&
                      Object.keys(formik.values).includes("first_name") &&
                      Object.keys(formik.values).includes("last_name")
                    ) {
                      // console.log("page is 0 ")
                      setPage(1);
                    } else {
                      // console.log(page)
                      //   setErr("Fill in the required fields")
                      //   setTimeout(()=>{
                      //       setErr(null)
                      //   },2000)
                    }
                  }}
                >
                  {page == 1 ? "Save" : "Continue"}
                </button>
              </div>
            )}
          </div>

          {/* {page === 0 ? (
            <>
              <div className="flex flex-row gap-2 items-center mt-8 mb-4">
                <hr className="w-[100%] h-[0.1px] bg-grey border-0 rounded bg-grey" />
                Or
                <hr className="w-[100%] h-[0.1px] bg-grey border-0 rounded bg-grey" />
              </div>

              <button
                className="w-[100%] flex items-center justify-center bg-white border border-1 border-primary text-primary font-[Gilroy] rounded-full h-[40px]"
                type="submit"
              >
                <span>
                  <AiOutlineGoogle size={22} />
                </span>
                &nbsp; Sign in with Google
              </button>

              <br />
              <button
                className="w-[100%] flex items-center justify-center bg-white border border-1 border-blue text-blue font-[Gilroy] rounded-full h-[40px]"
                type="submit"
              >
                <span>
                  <FaFacebook size={22} />
                </span>
                &nbsp; Sign in with Facebook
              </button>
            </>
          ) : null} */}
        </form>
      </FormikProvider>
    </BaseModal>
  );
};
