import React, { useEffect, useState } from "react";
import NavbarUserCard from "./NavbarUserCard";
import { MdOutlineShoppingBag } from "react-icons/md";
import "../index.css";
import { CartCard } from "./CartCard/CartCard";
import { getUserCartItems } from "../api/cart/cart";
import logo from "../assets/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { getReverseGeocodingAddress } from "../api/misc/misc";
import { FiCamera } from "react-icons/fi";
import Marker from "./Marker";
import GoogleMapReact from "google-map-react";

function NavbarGuest() {
  const navigate = useNavigate();
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [cartItems, setCartItems] = useState<any[]>([]);
  const [updateCart, setUpdateCart] = useState<any>(false);
  const [userLocation, setUserLocation] = useState<any>(null);

  const handleCartButton = () => {
    if (isCartOpen) {
      setTimeout(() => {
        setIsCartOpen(false);
      }, 300);
    } else setIsCartOpen(!isCartOpen);
  };

  useEffect(() => {
    window.navigator.geolocation.getCurrentPosition(function (pos) {
      console.log("locatpion res1: ", {
        lat: pos.coords.latitude,
        lng: pos.coords.longitude,
      });
      getLocation({ lat: pos.coords.latitude, lng: pos.coords.longitude });
    });
  }, []);

  const getLocation = async (raw: any) => {
    const res = await getReverseGeocodingAddress(raw);
    console.log("locatpion res ::", res?.data?.results[0]?.formatted_address);
    // setUserLocation(res?.data?.results[0]?.formatted_address)
    setUserLocation(res?.data?.error_message);
  };

  return (
    <>
      <div className=" pt-3 pb-3 bg-white z-[100] mb-[5px] nav-shadow">
        <div className="w-[85%] m-auto flex flex-row items-center">
          <div className="basis-[10%] w-[70%] text-left">
            <Link to="/restaurants">
              <img width="50px" src={logo} />
            </Link>
          </div>

          <div className="xs:hidden sm:hidden md2:block md:block lg:block xl:block 1xl:block 2xl:block 3xl:block 4xl:block basis-[72%] w-[70%] text-center font-[Gilroy] text-[12px]">
            {userLocation}
          </div>

          <div className="basis-[20%] flex-1 2xl:w-[10%] xl:w-[10%] 1xl:w-[10%] justify-end">
            <div className="flex flex-row items-center justify-evenly">
              <div
                onClick={() => {
                  navigate("/login");
                }}
                className="basis-[32%] flex items-center justify-center h-[40px] pr-1 pl-1 rounded-xl bg-primary text-white font-[Gilroy] cursor-pointer"
              >
                Sign in
              </div>

              <div
                onClick={() => {
                  navigate("/register");
                }}
                className="basis-[32%] flex items-center justify-center h-[40px] pr-1 pl-2 rounded-xl bg-primary text-white font-[Gilroy] cursor-pointer"
              >
                Sign up
              </div>

              <div className="basis-[10%] flex items-center justify-center">
                {window.location.pathname !== "/" && (
                  <div className="flex basis-1/4 flex-row items-center justify-end font-[Gilroy]">
                    {localStorage.getItem("u%c%") ? (
                      <>
                        <div
                          className="cursor-pointer"
                          onClick={handleCartButton}
                        >
                          <MdOutlineShoppingBag size={30} color={"#fa4f26"} />
                        </div>
                        <div className=" relative top-[-11px] left-[-10px]">
                          <Marker id="1" />
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="cursor-pointer"
                          onClick={handleCartButton}
                        >
                          <MdOutlineShoppingBag size={30} color={"#fa4f26"} />
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {isCartOpen && (
          <CartCard
            cartItems={cartItems}
            setUpdateCart={setUpdateCart}
            updateCart={updateCart}
            handleClose={handleCartButton}
          />
        )}
      </div>

      {userLocation && (
        <div className=" pt-2 pb-2 mt-3 mb-3 bg-white xs:block sm:block md2:hidden md:hidden lg:hidden xl:hidden 1xl:hidden 2xl:hidden 3xl:hidden 4xl:hidden">
          <div className="w-[85%] m-auto  flex flex-row items-center pt-2 xs:block sm:block md2:hidden md:hidden lg:hidden xl:hidden 1xl:hidden 2xl:hidden 3xl:hidden 4xl:hidden basis-[72%] text-center font-[Gilroy] text-[12px]">
            {userLocation}
          </div>
        </div>
      )}
    </>
  );
}

export default NavbarGuest;
