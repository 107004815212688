import React from 'react';
import {AiOutlineUser} from "react-icons/ai"
import {Menu, MenuButton, MenuItem, SubMenu} from "@szhsin/react-menu";
import {useNavigate} from "react-router-dom"
import {IMG_STORAGE_BASE_URL} from "../config";

function NavbarUserCard() {
    const navigate = useNavigate();
    let user:any = localStorage.getItem("user")
    user = JSON.parse(user)

    return (
        <div className="flex items-center">
                {/*<div className="2xl:basis-1/4 xl:basis-1/4 1xl:basis-0 items-center ">*/}

                {/*</div>*/}


            <Menu menuButton={<MenuButton className="w-[100%]">

                <div className="flex 2xl:basis-3/4 xl:basis-[100%] 1xl:basis-[100%] md2:basis-[100%] md:basis-[100%] items-center text-white border-primary p-[0.5rem] pr-2 pl-2 rounded-md bg-primary w-[100%] min-w-[165px] ">
                    <div className="bg-primary-light-200 border-primary p-1.5 rounded-md mr-2"
                    >
                        {
                            user.profile_image?
                                <img width="20px" src={IMG_STORAGE_BASE_URL+user.profile_image}/>
                                :
                                <AiOutlineUser size={20} color={"#fa4f26"} />
                        }

                    </div>

                    <div className="flex-col text-[14px] font-[Gilroy-Semibold] text-left truncate">
                        <p>
                            {
                                user.first_name[0].toUpperCase() +
                                user.first_name.slice(1) +
                                " " +  user.last_name[0].toUpperCase() +
                                user.last_name.slice(1)
                            }
                        </p>
                        {/*<p className="flex-col text-[12px] font-[Gilroy] text-left">*/}
                        {/*    ID-{user.id}*/}
                        {/*</p>*/}
                    </div>
                </div>

            </MenuButton>}>
                    <div className="bg-white p-2 rounded-md shadow-xl text-left min-w-[190px] border-none">
                        <MenuItem className="cursor-pointer border-none" onClick={()=>{
                            navigate('/edit-profile')
                        }}>
                            <div className="flex 2xl:basis-3/4 xl:basis-[100%] 1xl:basis-[100%] items-center p-2 w-[100%] hover:bg-[#FAF42633] border-none">
                                {/*<div*/}
                                {/*    className="bg-primary-light-200 border-primary p-1.5 rounded-md mr-5">*/}
                                {/*    <AiOutlineUser size={20} color={"#fa4f26"} />*/}
                                {/*</div>*/}

                                <div className="flex-col text-[14px] font-[Gilroy-Semibold] text-left border-none">
                                    <p>
                                        {
                                            user.first_name[0].toUpperCase() +
                                            user.first_name.slice(1) +
                                            " " +  user.last_name[0].toUpperCase() +
                                            user.last_name.slice(1)
                                        }
                                    </p>
                                    {/* <p className="flex-col text-[12px] font-[Gilroy] text-left">
                                        {user.email}
                                    </p> */}
                                </div>
                            </div>
                        </MenuItem>

                        <hr className="h-px bg-[#F5F5F5] border-0" />


                        <MenuItem className="flex 2xl:basis-3/4 xl:basis-[100%] 1xl:basis-[100%] items-center p-2 w-[100%] cursor-pointer hover:bg-[#FAF42633] border-none">
                            <div className="border-none" onClick={()=>{
                                navigate('/favourites')
                            }}>
                                Favourites
                            </div>
                        </MenuItem>


                        <hr className="h-px bg-[#F5F5F5] border-0" />

                        <MenuItem className="flex 2xl:basis-3/4 xl:basis-[100%] 1xl:basis-[100%] items-center p-2 w-[100%] cursor-pointer hover:bg-[#FAF42633] border-none" onClick={()=>{
                            localStorage.clear();
                            setTimeout(()=>{
                                // navigate("/")
                                window.location.href ="/";
                            },1000)

                        }}>
                            <div className="border-none">
                                Logout
                            </div>
                        </MenuItem>
                    </div>


                {/*<SubMenu label="Edit">*/}
                {/*    <MenuItem>Cut</MenuItem>*/}
                {/*    <MenuItem>Copy</MenuItem>*/}
                {/*    <MenuItem>Paste</MenuItem>*/}
                {/*</SubMenu>*/}
            </Menu>




        </div>

    );
}

export default NavbarUserCard;
