import React from "react";

import { FiArrowLeft } from "react-icons/fi";

const PrivacyPolicy = () => {
  return (
    <div className="w-[100%] bg-white">
      <div className="p-0 pl-0 pr-0 pt-10 bg-[white] h-[780px] leading-loose text-left font-[Gilroy] text-[13px]">
        <p className="font-semibold px-48 text-[16px] w-[70%]">
          Please read the following instructions carefully before filling in the
          form:
        </p>
        <ul className="list-outside list-disc mx-48 w-[70%] ">
          <li>
            This web portal is meant for attestation of degrees awarded by
            recognized Universities/Degree Awarding Institutes (DAIs) of
            Pakistan.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
