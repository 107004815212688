import restBanner from "../assets/rest-home-banner.png"
import {RestaurantInfoCard} from "../components/RestaurantInfoCard";
import {BiSolidMap} from "react-icons/bi";
import {MenuCard} from "../components/MenuCard";
import {MenuModal} from "../components/MenuModal";
import {Link, useLocation} from 'react-router-dom';
import {useEffect, useState} from "react";
import {MdArrowBackIosNew} from "react-icons/md";
import {addItemToCart} from "../api/cart/cart";
import {array} from "yup";
import {IMG_STORAGE_BASE_URL} from "../config";
import Footer from "../components/Footer";

export const RestaurantHome = () =>{
    const location = useLocation();
    const menuList: any[] = location?.state?.menu;
    console.log("menuList ::::: ", menuList)
    const [openModal, setOpenModal] = useState(false);
    const [menu, setMenu] = useState<any[]>([]);
    const [name, setName] = useState<any>(null);
    const [address, setAddress] = useState<any>(null);
    const [selectedMenu, setSelectedMenu] = useState<any>({});


    useEffect(()=>{
        if(!menuList){
            let restList:any = localStorage.getItem("r%L");
            restList = JSON.parse(restList)
            if(restList.length > 0){
                let my = restList.filter((r:any)=> r.id == location.pathname.split("/")[2])
                console.log("asdsssadfdsafsaf ::: ", my[0])
                setName(my[0].business_name);
                setAddress(my[0].restaurant_address)
                setMenu(my[0].restaurant_menue)
            }

            // setMenu
        }
        else{
            setMenu(menuList)
            setName(location.state.name)
            setAddress(location.state.address)
        }

    },[menuList])

    const handleAddToCart = async (m:any) => {
        let payload:any = {
            restaurant_menue_id: m.id,
            quantity: 1,
        }
        if(localStorage.getItem("accessToken")){
            const res = await addItemToCart(payload)
            if(res?.request?.status !== 200){
                // setErr(res?.response?.data.message)
            }
        }
        else {
            payload.restaurant_menue = {};
            payload.id = m.id;
            payload.restaurant_menue["regular_price"] = m.regular_price;
            payload.restaurant_menue["item_name"] = m.item_name;
            payload.restaurant_menue["description"] = m.description;
            payload.restaurant_menue["restaurant_id"] = m.restaurant_id;
            payload["restaurant_file"] = m.restaurant_file;
            let data:any = [];
            let prevList:any = localStorage.getItem("u%c%")
            if(prevList){
                prevList = JSON.parse(prevList)
                data = [...prevList, payload];
                localStorage.setItem("u%c%", JSON.stringify(data) )
            }
             else
                localStorage.setItem("u%c%", JSON.stringify([payload] ))
        }
    }

    const handleModalOpen = (m:any) =>{
        if(m.restaurant_menue_variant.length > 0){
            console.log("eeeeeeeeeeeeee", m)
            setSelectedMenu(m)
            setOpenModal(true);
        }
        else{
            handleAddToCart(m)
        }

    }
    const handleClose = (menuId:any) =>{
        setOpenModal(false);
    }


    return(
        <div className="rest-home bg-[white]  p-0 pl-0 pr-0">
            <div className="w-[85%] m-auto">

                <div className="flex flex-col w-[100%] justify-center">
                    <div className="rest-banner bg-[black] contents mb-2">
                        <img className="flex flex-row justify-center h-[380px]" height="360px" src={location?.state?.image? IMG_STORAGE_BASE_URL+location?.state?.image : restBanner} />
                        <div className="flex w-[100%] pl-6 justify-start relative 2xl:top-[-360px] xl:top-[-320px] 1xl:top-[-290px] lg:top-[-260px] md:top-[-240px] ">
                            <div className="rounded-full bg-primary border border-primary border-1 p-2 text-center mt-[-16px]">
                                <Link to="/restaurants">
                                    <MdArrowBackIosNew className="text-white" size={25}  style={{
                                        opacity: "100%"
                                    }}/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Title bar */}
                <div className="title-bar w-[90%] m-auto pb-20 box-shadow">
                    <div className="text-primary  3xl:flex 2xl:flex 1xl:flex xl:flex lg:block md2:block md:block pt-1 justify-between items-center w-[100%]">
                        <div className=" font-[Gilroy-Semibold] text-[40px] mt-[-10px]">
                            { name? name : "Unkown Restaurant"}
                        </div>
                        <div className="basis-[45%]">
                            <RestaurantInfoCard />
                        </div>
                    </div>
                    <div className="flex items-center text-[16px] font-[Gilroy]">
                        <BiSolidMap/>
                        &nbsp;
                        {address? address : "Location not speified"}
                    </div>
                </div>


                <div className="w-[90%] m-auto">
                    
                    <div className="grid 3xl:grid-cols-4 2xl:grid-cols-4 xl:grid-cols-4 1xl:grid-cols-4 lg:grid-cols-3 md2:grid-cols-2 md:grid-cols-2 pb-10 justify-between gap-2">
                        {
                            menu.map((m:any, i:number)=>(
                            <MenuCard
                                key={i}
                                id={m.id}
                                type="MENU"
                                name={m.item_name}
                                image={m.restaurant_file[0]?.restaurant_file}
                                variants={m.restaurant_menue_variant}
                                desc={m.description}
                                price={m.regular_price}
                                rating="3.1"
                                totalRatings="100+"
                                cuisines={m.description}
                                handleAddToCart={()=>handleModalOpen(m)}
                            />
                        ))
                        }

                    </div>
                </div>

                {
                    openModal &&
                    <MenuModal
                        menuDetails={selectedMenu}
                        handleClose={handleClose}
                    />
                }


            </div>

            <Footer />
        </div>
    );
}
