import restaurant from "../assets/restaurant.png"
import {AiFillStar, AiOutlineStar, AiTwotoneStar, AiFillHeart} from "react-icons/ai";
import {HiOutlineClock} from "react-icons/hi";
import {AbstractCard} from "./AbstractCard";

export function PopularMenuCard(props:any) {
    const {selected, name, nonClickable} = props;

    return (
        <AbstractCard
            nonClickable={nonClickable}
            type="POPULAR"
            name="Thai Famous Cuisine"
            rating="3.1"
            totalRatings="100+"
            cuisines="North Indian . Indian . Pure Veg"
        />

        // <div className={`p-0 max-w-[250px] max-h-[292px] rounded-xl justify-items-center ${selected? "bg-primary" : "bg-white"}`}>
        //     <div className="rest-card-img 2xl:max-h-[8rem] xl:max-h-[7.5rem] 1xl:max-h-[7rem] lg:max-h-[6rem] md:max-h-[4.3rem] static">
        //         <img
        //         src={restaurant}
        //         />
        //         <div className="flex w-[100%] pr-3.5 justify-end relative 2xl:top-[-130px] xl:top-[-115px] 1xl:top-[-107px] lg:top-[-90px] md:top-[-90px] ">
        //             <div className="rounded-full bg-white p-1 text-primary">
        //                 <AiFillHeart size={15}  style={{
        //                     opacity: "100%"
        //                 }}/>
        //             </div>
        //         </div>
        //         <div className="flex w-[100%] pr-4 justify-end relative top-[-50px] text-[12px] font-[Gilroy] text-white">
        //             <div className="flex flex-row rounded-xl bg-primary w-[80px] h-[20px] justify-center items-center">
        //                 <AiFillStar color={"white"} size={15}  /> &nbsp;
        //                 <p>
        //                     3.1(100+)
        //                 </p>
        //             </div>
        //
        //         </div>
        //     </div>
        //
        //     <div className="rest-card-content p-[0.6rem] 2xl:pt-[1rem] xl:pt-[1rem] lg:pt-[0.7rem] mr-[1px] border-2 border-t-0 border-primary rounded-bl-xl rounded-br-xl
        //                     2xl:max-h-[142px] xl:max-h-[130px] 1xl:max-h-[125px] lg:max-h-[122px] ">
        //         <div className="text-left 2xl:text-[18px] xl:text-[18px] 1xl:text-[16px] lg:text-[14px] md:text-[10px]
        //          font-[Gilroy-Semibold] text-primary">
        //            Thai Famous Cuisine
        //         </div>
        //         <div className="text-left font-[Gilroy] text-grey md:text-[6px] lg:text-[9px] 1xl:text-[10px] xl:text-[12px] 2xl:text-[14px]">
        //             North Indian . Indian . Pure Veg
        //         </div>
        //         <div className="flex flex-row gap-1 mt-3 mb-3">
        //             <AiFillStar color={"#FA4F26"} size={16} />
        //             <AiFillStar color={"#FA4F26"} size={16} />
        //             <AiFillStar color={"#FA4F26"} size={16} />
        //             <AiFillStar color={"#FA4F26"} size={16} />
        //             <AiOutlineStar color={"grey"} size={16} />
        //         </div>
        //         <div className="flex flex-row gap-3 mt-3 mb-3 items-center">
        //             <div className="rounded-xl bg-primary w-[50px] h-[17px] text-[11px] font-[Gilroy] text-white">
        //                 Offer
        //             </div>
        //             <div className="text-left 2xl:text-[18px] xl:text-[18px] 1xl:text-[16px] lg:text-[14px] md:text-[10px] font-[Gilroy-Semibold] text-primary">
        //                     65% Off
        //             </div>
        //         </div>
        //
        //     </div>
        //
        // </div>
    );
}

