import {BiSolidMap} from "react-icons/bi";

const Marker = (props:any) => {
    return <div className="z-[999]">
        <div style={{
            backgroundColor: props.backgroundColor? props.backgroundColor : 'red',
            color: 'white',
            padding: props.padding? `${props.padding} ${props.padding}` : '5px 5px',
            borderRadius: '50%',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            // zIndex: "9999"
        }}>
            {
                props.map?
                    <BiSolidMap/>: null
            }

        </div>
    </div>
}

export default Marker;
