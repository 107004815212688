import GoogleMapReact from 'google-map-react';
import {useEffect, useState} from "react";
import {BiSolidMap} from "react-icons/bi";
import {getReverseGeocodingAddress} from "../api/misc/misc";
import Marker from "./Marker";

function Map (){
    // const Marker = () => (
    //     <div style={{
    //         backgroundColor: 'red',
    //         color: 'white',
    //         padding: '5px 10px',
    //         borderRadius: '50%',
    //         display: 'inline-flex',
    //         alignItems: 'center',
    //         justifyContent: 'center',
    //         // zIndex: "9999"
    //     }}>
    //         You
    //     </div>
    // );


    const [latlong, setLatlong] = useState<any>(null)
    useEffect(()=>{
        window.navigator.geolocation.getCurrentPosition(function (pos) {
            const lat = pos.coords.latitude;
            const lng = pos.coords.longitude;
            setLatlong({
                center: {
                    lat,
                    lng
                },
                zoom: 15
            });
            getLocation({lat: pos.coords.latitude, lng: pos.coords.longitude})

        })
    }, [])

    const getLocation = async (raw:any) => {
        const res = await getReverseGeocodingAddress(raw);
        console.log("locatpion res ::" , res.data.results[0].formatted_address)
    }


    const defaultProps = {
        center: {
            lat: latlong? latlong[0] : 35.5773696,
            lng: latlong? latlong[1] : 75.5773696
        },
        zoom: 15
    };
    const mapOptions = {
        disableDefaultUI: true, // Disable all default UI controls
        scrollwheel: false, // Disable mouse wheel zoom
    };


    return(
        <>
            {   latlong &&
                <div className="w-[100%] h-[100%]">
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: "AIzaSyBc5tAysc75ml0PDPcY4DpiMEbCK5qV-ao" }}
                        defaultCenter={latlong.center}
                        defaultZoom={defaultProps.zoom}
                        options={mapOptions}
                    >
                        <Marker id="1" map={true} lat={latlong.center.lat} lng={latlong.center.lng} />
                    </GoogleMapReact>

                </div>
            }
        </>
    )
}
//
//
// //
// // <GoogleMapReact
// //     bootstrapURLKeys={{ key: /* YOUR KEY HERE */ }}
// //     defaultCenter={this.props.center}
// //     defaultZoom={this.props.zoom}
// //     yesIWantToUseGoogleMapApiInternals
// //     onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
// // >
//
//
export default Map;
