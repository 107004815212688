import React, { useState } from "react";
import "../index.css";
import phones from "../assets/footer-phones.png";
import ApplePlay from "../assets/apple-logo.svg";
import Googleplay from "../assets/google-logo.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const PlayStore = () => {
  const navigate = useNavigate();
const googleHandle=()=>{
  navigate("/")
}
const appleHandle=()=>{
  navigate("/")
}

  return (
    <div className=" mt-[10rem] w-full mb-[10rem] bg-primary rounded-md h-[330px] font-[Gilroy] ">
      <div className="font-[Gilroy-Semibold] font-bold text-[28px] max-w-[400px] relative top-[-66px] text-left">
        Put us in your Pocket
      </div>

      <div className="flex flex-row h-[400px]">
        <div className="basis-[60%] pl-10 pt-10 pb-0">
          <div
            className="font-[Gilroy-Semibold] 4xl:text-[22px] 3xl:text-[22px] 2xl:text-[22px] xl:text-[22px] 1xl:text-[22px]
                lg:text-[18px] md2:text-[16px] md:text-[14px] sm:text-[12px] xs:text-[12px]
                relative top-[-30px] text-white text-left"
          >
            Download our App to Order your Favourite Food
          </div>
          <div
            className="font-[Gilroy] relative top-[-15px] mb-4 text-left text-white pr-[22%] !font-thin
                4xl:text-[14px] 3xl:text-[14px] 2xl:text-[14px] xl:text-[14px] 1xl:text-[14px]
                lg:text-[12px] md2:text-[12px] md:text-[10px] xs:text-[9px] sm:text-[9px] md2:block md:block lg:block xl:block 1xl:block 2xl:block 3xl:block 4xl:block"
          >
            Its all at your fignertips - the resturants and shops you love. Find
            the right food and groceries to suit your mood, and make the first
            bite last. Go ahead and download us
          </div>
          <div className="flex flex-row gap-3">
            <div onClick={googleHandle} className='flex items-center gap-3 bg-[black] rounded-lg py-1 px-2 cursor-pointer'>
              <img src={Googleplay} alt="" width={24} height={24} />
              <div className='flex flex-col items-start'>
                  <span className='text-[#FFFFFF] font-normal text-[12px]'>GET IT ON</span>
                  <span className='text-[#FFFFFF] font-sm text-[12px]'>Google Play</span>
              </div>
            </div>
            <div onClick={appleHandle} className='flex items-center gap-3 bg-[black] rounded-lg py-1 px-3 cursor-pointer'>
              <img src={ApplePlay} alt="" width={24} height={24} />
              <div className='flex flex-col items-start'>
                  <span className='text-[#FFFFFF] font-normal text-[12px]'>GET IT ON</span>
                  <span className='text-[#FFFFFF] font-sm text-[12px]'>App Store</span>
              </div>
            </div>
          </div>
        </div>
        
        <div className="basis-[40%] flex justify-end mr-[3rem]">
          <img width="99%" className="relative 4xl:bottom-[43%] 3xl:bottom-[43%] 2xl:bottom-[43%] 1xl:bottom-[28%] xl:bottom-[37%] lg:bottom-[22%] md2:bottom-[17%]" src={phones} />
        </div>

      </div>
    </div>
  );
};

export default PlayStore;
