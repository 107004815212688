import {
    deleteRequest,
    getRequest, getThirdPartyRequest, patchRequest, postFormDataRequest,
    postRequest
} from "../methodCalls";
import {
    cuisineListURI, deleteUserFavMenuURI, editUserProfileURI, verifyEmailOtpURI,
} from "../endpoints";

// let g = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;

export const getCuisineList = (params) => {
    return getRequest(`${cuisineListURI}`);
}

export const getReverseGeocodingAddress = (params) => {
    console.log("locatpion", params)
    return getThirdPartyRequest(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${params.lat},${params.lng}&key=AIzaSyBc5tAysc75ml0PDPcY4DpiMEbCK5qV-ao`);
}

export const editUserProfile = (payload) => {
    return postFormDataRequest(editUserProfileURI, payload);
}

export const verifyEmailOtp = (payload) => {
    console.log("data:: ",payload, verifyEmailOtpURI)
    return postFormDataRequest(verifyEmailOtpURI, payload);
}


