
import {CheckoutModal} from "../components/CheckoutModal";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

export const Checkout = () =>{
    const navigate = useNavigate();

    useEffect(()=>{
        const token = localStorage.getItem("accessToken")
        const user = localStorage.getItem("user")
        if(!token && !user){
            navigate('/login')
        }
    },[])

    return(
        <div className="rest-home bg-[white] p-0 pl-0 pr-0">
           <CheckoutModal />
        </div>
    );
}
