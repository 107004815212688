import { BaseModal } from "./BaseModal";
import { FaCcVisa } from "react-icons/fa";
import { ErrorMessage, Field, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { getLoginUser } from "../api/auth/auth";
import { saveUserAddress } from "../api/address/address";
import { useState } from "react";
import { addUserPaymentMethod } from "../api/payment/payment";
import { useNavigate } from "react-router-dom";

export const AddNewPaymentModal = ({ handleClose }: any) => {
  const navigate = useNavigate();
  const [err, setErr] = useState<any>(null);

  const handleAddPayment = async (raw: any) => {
    const payload = {
      card_holder_name: raw.name,
      card_number: raw.account_number,
      exp_month: raw.expiry.split("/")[0],
      exp_year: raw.expiry.split("/")[1],
      payment_status: "Stripe",
      cvc: raw.cvv,
    };
    const res = await addUserPaymentMethod(payload);
    console.log(" payment res", res);
    if (res?.request?.status !== 200) {
      setErr(res?.response?.data.message);
      setTimeout(() => {
        setErr(null);
      }, 2000);
    } else {
      handleClose();
      navigate(0);
    }
  };

  const paymentSchema = Yup.object().shape({
    name: Yup.string().required("Invalid input entered"),
    account_number: Yup.string()
      .required("Required")
      .min(16, "Enter Valid Card Number"),
    expiry: Yup.string().required("Required").min(5, "Enter Valid Date!"),
    cvv: Yup.string().required("Required").min(3, "Enter Valid CVV!"),
  });

  const formik = useFormik({
    initialValues: {},
    validationSchema: paymentSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify({...values, user_login_status: "customer" }, null, 2));
      handleAddPayment(values);
    },
  });

  return (
    <BaseModal handleClose={handleClose}>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div className="main pt-[-5px]">
            <div className="flex flex-row gap-5">
              <div className="text-white">
                <FaCcVisa className="p-[7px] rounded-xl bg-primary" size={40} />
              </div>
              <div>
                <div className="font-[Gilroy-Semibold] text-[16px] text-left">
                  Creadit / Debit Card
                </div>
                <div className="font-[Gilroy] text-[12px] text-left">
                  Your Card Details
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-2 pt-5 w-[80%]">
              <div className="flex flex-col items-left text-left font-[Gilroy] text-[14px] text-black">
                <label htmlFor="name">Card Holder</label>
                <Field
                  className="font-[Gilroy] text-[14px] text-sec-grey h-[35px] outline-none focus:outline-none border-b border-underline-c"
                  id="name"
                  name="name"
                  placeholder="Enter your Name"
                  type="string"
                />
                <div className="font-[Gilroy] text-[10px] text-primary pb-2">
                  <ErrorMessage name="name" component="div" className="error" />
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-2 pt-5 w-[80%]">
              <div className="flex flex-col items-left text-left font-[Gilroy] text-[14px] text-black">
                <label htmlFor="account_number">Account Number</label>
                <Field
                  className="font-[Gilroy] text-[14px] text-sec-grey h-[35px] outline-none focus:outline-none border-b border-underline-c"
                  id="account_number"
                  name="account_number"
                  placeholder="1234 5678 0000 0000"
                  type="string"
                />
                <div className="font-[Gilroy] text-[10px] text-primary pb-2">
                  <ErrorMessage
                    name="account_number"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
            </div>

            <div className="pt-5 pb-4">
              <div className="flex flex-row gap-5">
                <div className="basis-1/2 flex flex-col gap-2 ">
                  <div className="flex flex-col items-left text-left font-[Gilroy] text-[14px] text-black">
                    <label htmlFor="expiry">Expiry Date</label>
                    <Field
                      className="font-[Gilroy] text-[14px] text-sec-grey h-[35px] outline-none focus:outline-none border-b border-underline-c"
                      id="expiry"
                      name="expiry"
                      placeholder="Ex. 7/21"
                      type="string"
                    />
                    <div className="font-[Gilroy] text-[10px] text-primary pb-2">
                      <ErrorMessage
                        name="expiry"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                </div>

                <div className="basis-1/2 flex flex-col gap-2 ">
                  <div className="flex flex-col items-left text-left font-[Gilroy] text-[14px] text-black">
                    <label htmlFor="cvv">CVV</label>
                    <Field
                      className="font-[Gilroy] text-[14px] text-sec-grey h-[35px] outline-none focus:outline-none border-b border-underline-c"
                      id="cvv"
                      name="cvv"
                      placeholder="***"
                      type="string"
                    />
                    <div className="font-[Gilroy] text-[10px] text-primary pb-2">
                      <ErrorMessage
                        name="cvv"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button className=" mt-5 pr-20 pl-20 p-2 rounded-full bg-primary text-white font-[Gilroy-Semibold]">
              Add
            </button>
          </div>
        </form>
      </FormikProvider>
    </BaseModal>
  );
};
