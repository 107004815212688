import { LoginForm } from "../components/LoginForm";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import OrderFailed from "../assets/order-failed.png";
import { confirmOrderPayment } from "../api/order/order";
import Map from "../components/Map";
import avatar from "../assets/avatar.png";
import { FiArrowLeft, FiCamera } from "react-icons/fi";
import { ErrorMessage, Field, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { getLoginUser } from "../api/auth/auth";
import { BaseModal } from "../components/BaseModal";
import fs from "fs";
import { editUserProfile, verifyEmailOtp } from "../api/misc/misc";
import { IMG_STORAGE_BASE_URL } from "../config";
import { verify } from "crypto";
import Footer from "../components/Footer";

export const EditProfile = () => {
  const [apiLoading, setApiLoading] = useState(false);
  const otpRef = useRef(null);
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    const user = localStorage.getItem("user");
    if (!token && !user) {
      navigate("/login");
    }
  }, []);

  const navigate = useNavigate();
  const myRefname = useRef<HTMLInputElement>(null);
  const [profilePic, setProfilePic] = useState<any>(null);
  const [err, setErr] = useState<any>(null);
  let user: any = localStorage.getItem("user");
  user = JSON.parse(user);
  const [payErr, setPayErr] = useState<any>(null);

  const loginSchema = Yup.object().shape({
    email: Yup.string().email().required("Invalid input entered"),
    password: Yup.string().required("Required").min(3, "Too Short!"),
  });

  const handleUpdate = async (payload: any) => {
    if (!Object.keys(payload).includes("name")) {
      payload.first_name = user?.first_name;
      payload.last_name = user?.last_name;
    } else {
      payload.first_name = payload.name.split(" ")[0];
      payload.last_name = payload.name.split(" ")[1]
        ? payload.name.split(" ")[1]
        : "";
    }
    if (!Object.keys(payload).includes("phone_number")) {
      payload.phone_number = user?.phone_number;
    }

    if (profilePic) {
      let data: any = profilePic[0];
      payload.profile_image = data;
    }
    payload.email = user?.email;
    const res = await editUserProfile(payload);
    console.log("res ::", res);
    if (!res.status) {
      setErr(res?.response?.data?.message);
    } else {
      localStorage.setItem("user", JSON.stringify(res.data.records));
      window.location.reload();
    }
  };

  const formik = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      handleUpdate(values);
    },
  });

  const verifyEmail = async () => {
    // e.stopPropagation();
    console.log("email verification called...", otpRef);
    let otpVal: any = otpRef?.current;
    let payload = {
      email_verification_code: otpVal?.value,
      new_password: sessionStorage.getItem("userStatusPending"),
      confirm_password: sessionStorage.getItem("userStatusPending"),
    };

    setApiLoading(true);
    const res = await verifyEmailOtp(payload);
    setApiLoading(true);
    if (res?.request?.status !== 200) {
      setApiLoading(false);
      setErr(res?.response?.data.message);
      setTimeout(() => {
        setErr(null);
      }, 2000);
    } else {
      setApiLoading(false);
      console.log(
        res?.data?.message ==
          "We do not recognize this email address. Please try again."
      );
      setErr(res?.data.message);
      setTimeout(() => {
        setErr(null);
      }, 2000);
      // if()
      // navigate("/dashboard");
    }
  };

  return (
    <div className="rest-home bg-[white]  p-0 pl-0 pr-0">
      <div className="w-[70%] mx-auto pt-[1rem]">
        {/* check out text */}
        <div className="flex items-center gap-2">
          <FiArrowLeft
            className="bg-primary cursor-pointer bg-opacity-20 text-primary"
            style={{
              borderRadius: "0.7rem",
              padding: "2px",
              fontSize: "32px",
            }}
            onClick={() => {
              window.history.back();
            }}
          />
          <div className="text-[28px] font-[Gilroy-Semibold] text-left">
            Edit Profile
          </div>
        </div>

        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <div
              className="flex w-[100%]  4xl:flex-row 3xl:flex-row 2xl:flex-row xl:flex-row 1xl:flex-row lg:flex-row
                                 md2:flex-col md:flex-col sm:flex-col xs:flex-col"
            >
              <div className="basis-[30%] max-h-[270px] app-shadow rounded-xl m-4">
                <div className="flex justify-center">
                    <img
                      width="225px"
                      src={
                        user?.profile_image
                          ? IMG_STORAGE_BASE_URL + user.profile_image
                          : avatar
                      }
                    />
                
                </div>
                <div className="flex flex-row justify-end text-primary">
                  <div
                    className="p-2 bg-primary bg-opacity-20 rounded-full cursor-pointer relative bottom-[-20px]"
                    onClick={() => {
                      if (myRefname.current) {
                        myRefname.current.click();
                      }
                    }}
                  >
                    <input
                      onChange={async (e: any) => {
                        console.log("changess", e.target.files[0]);
                        setProfilePic(e.target.files);
                      }}
                      id="pic"
                      name="pic"
                      type="file"
                      ref={myRefname}
                      className="hidden"
                    />
                    <FiCamera size={20} />
                  </div>
                </div>
              </div>

              <div className="basis-[70%] max-h-[700px]">
                <div className="flex flex-col gap-2 p-10 app-shadow rounded-xl m-4">
                  <div className="flex flex-col items-left text-left font-[Gilroy] text-[14px] text-black">
                    <label htmlFor="name">Name</label>
                    <Field
                      className="font-[Gilroy] text-[14 px] text-sec-grey h-[35px] outline-none focus:outline-none border-b border-underline-c"
                      id="name"
                      name="name"
                      placeholder="Enter your Name"
                      defaultValue={user?.first_name + " " + user?.last_name}
                    />
                    <div className="font-[Gilroy-Semibold] text-[10px] text-primary pb-2">
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>

                  <div className="flex flex-col items-left text-left font-[Gilroy] text-[14px] text-black">
                    <label htmlFor="phone_number">Phone Number</label>
                    <Field
                      className="font-[Gilroy] text-[14px] text-sec-grey h-[35px] outline-none focus:outline-none border-b border-underline-c"
                      id="phone_number"
                      name="phone_number"
                      placeholder="Enter your Phone"
                      defaultValue={user?.phone_number}
                    />
                    <div className="font-[Gilroy-Semibold] text-[10px] text-primary pb-2">
                      <ErrorMessage
                        name="phone_number"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>

                  <div className="flex flex-row gap-2 justify-end">
                    <button
                      className="p-2 pr-4 pl-4 w-[100px] border-primary border-1 border rounded-full font-[Gilroy-Semibold] bg-primary bg-opacity-20 text-primary"
                      onClick={() => {
                        navigate("/dashboard");
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="p-2 pr-4 pl-4 w-[100px] border-primary border-1 border rounded-full font-[Gilroy-Semibold] bg-primary text-white"
                    >
                      Save
                    </button>
                  </div>
                </div>

                <div className="flex flex-col gap-2 p-10 app-shadow rounded-xl m-4">
                  <div className="flex flex-col items-left text-left font-[Gilroy] text-[14px] text-black">
                    <label htmlFor="name">Email</label>
                    <Field
                      className="font-[Gilroy] text-[14   px] text-sec-grey h-[35px] outline-none focus:outline-none border-b border-underline-c"
                      id="name"
                      name="name"
                      placeholder="Enter your Name"
                      defaultValue={user?.email}
                    />
                    <div className="font-[Gilroy-Semibold] text-[10px] text-primary pb-2">
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="error"
                      />
                    </div>

                    {user.user_status == "Pending" && (
                      <>
                        <label htmlFor="name">OTP</label>
                        <input
                          ref={otpRef}
                          className="font-[Gilroy] text-[14   px] text-sec-grey h-[35px] outline-none focus:outline-none border-b border-underline-c"
                          id="otp"
                          name="otp"
                          placeholder="Enter your OTP"
                        />
                        <div className="font-[Gilroy-Semibold] text-[10px] text-primary pb-2"></div>
                      </>
                    )}
                  </div>

                  <div className="flex flex-row gap-2 justify-end">
                    {user.user_status == "Pending" && !apiLoading && (
                      <button
                        type="button"
                        onClick={verifyEmail}
                        className="p-2 pr-4 pl-4 w-[100px] border-primary border-1 border rounded-full font-[Gilroy-Semibold] bg-primary text-white"
                      >
                        Verify
                      </button>
                    )}
                  </div>
                </div>
                {/*   </form>*/}
                {/*</FormikProvider>*/}
              </div>
            </div>
          </form>
        </FormikProvider>

        {err && (
          <div
            className={`font-[Gilroy] text-[16px] text-left p-3 w-[100%] bg-primary text-primary rounded-xl bg-opacity-30`}
          >
            {err}
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
};
