import axiosClient from "./apiClient";
import axios from "axios";

export function getThirdPartyRequest(URL) {
    return axios.get(`${URL}`).then(response => response).catch(err => err);
}

export function getRequest(URL) {
    return axiosClient.get(`/${URL}`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`,
            Accept: 'application/json'
        }
    }).then(response => response).catch(err => err);
}

export function postRequest(URL, payload) {
    return axiosClient.post(`/${URL}`, payload).then(response => response).catch(err => err);
}

export function patchRequest(URL, payload) {
    return axiosClient.patch(`/${URL}`, payload).then(response => response).catch(err => err);
}

export function deleteRequest(URL) {
    const accessToken = localStorage.getItem("accessToken");
    return axiosClient.delete(`/${URL}`,{headers: {
            'Authorization': `Bearer ${accessToken}`
        }}).then(response => response).catch(err => err);
}

export function postFormDataRequest(URL, payload) {
    const accessToken = localStorage.getItem("accessToken");
    return axiosClient.post(`/${URL}`, payload, {headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${accessToken}`,
            Accept: 'application/json'
        }}
    ).then(response => response).catch(err => err);
}
