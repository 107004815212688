import React, { useState } from "react";
import { IMG_STORAGE_BASE_URL } from "../config";
import restaurant from "../assets/restaurant.png";
import { AiFillStar, AiOutlineStar, AiFillHeart } from "react-icons/ai";
import { BiLoaderCircle } from "react-icons/bi";
import { HiOutlineClock } from "react-icons/hi";
import Notification from "./Notification";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export function AbstractCard(props: any) {
  const [heartClicked, setHeartClicked] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  const {
    type,
    name,
    price,
    rating,
    totalRatings,
    cuisines,
    startingPrice,
    deliveryTime,
    handleAddToCart,
    handleAddToFav,
    nonClickable,
    image,
    variants,
  } = props;

  const handleAddToCartWithNotification = () => {
    handleAddToCart();
    setShowNotification(true); 
    toast.success("Product added to cart!", {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    setTimeout(() => {
      setShowNotification(false); 
    }, 2000);
  };
  

  return (
    <div
      className={`p-0 mb-3 max-w-[250px] max-h-[300px] rounded-md justify-items-center bg-white app-shadow ${
        nonClickable ? "cursor-default" : "cursor-pointer"
      }`}
    ><ToastContainer />
      <div className="rest-card-img rounded-tl-md rounded-tr-md overflow-hidden max-h-[9.4rem] static">
        <img
          height="150px"
          className="object-cover flex flex-row justify-center h-[160px] w-[100%]"
          src={image ? IMG_STORAGE_BASE_URL + image : restaurant}
        />
        {!type ||
          (type.toUpperCase() !== "RESTAURANT" &&
            type.toUpperCase() !== "HOME KITCHEN" && (
              <div
                className={`flex w-[100%] pr-3.5 justify-end relative top-[-130px]`}
              >
                <div className="rounded-xl p-1 bg-primary text-white">
                  {!heartClicked ? (
                    <AiFillHeart
                      onClick={() => {
                        setHeartClicked(true);
                        handleAddToFav();
                        setTimeout(() => {
                          setHeartClicked(false);
                        }, 1000);
                      }}
                      size={15}
                      style={{
                        opacity: "100%",
                      }}
                    />
                  ) : (
                    <BiLoaderCircle
                      size={15}
                      style={{
                        opacity: "100%",
                      }}
                    />
                  )}
                </div>
              </div>
            ))}

        {rating && (
          <div className="flex w-[100%] pr-4 justify-end relative top-[-55px] text-[12px] font-[Gilroy] text-white">
            <div className="flex flex-row rounded-md bg-primary w-[80px] h-[20px] justify-center items-center">
              <AiFillStar color={"white"} size={15} /> &nbsp;
              <p>{rating ? `${rating} (${totalRatings})` : null}</p>
            </div>
          </div>
        )}
      </div>

      <div
        className={`rest-card-content min-[225px] w-[100%] p-[0.6rem] 3xl:pt-[0.5rem] ${
          type == "MENU"
            ? "2xl:pt-[0.5rem] xl:pt-[0.5rem] lg:pt-[0.7rem]"
            : "2xl:pt-[1rem] xl:pt-[0.4rem] lg:pt-[0.7rem]"
        } md2:pt-[0.25rem] mr-[1px] border border-1 border-t-0 border-primary rounded-bl-md rounded-br-md ${
          type == "MENU"
            ? "2xl:max-h-[135px] xl:max-h-[130px] lg:max-h-[110px]"
            : "2xl:max-h-[150px] xl:max-h-[130px] lg:max-h-[125px] md2:max-h-[115px] md:max-h-[120px] sm:max-h-[130px] xs:max-h-[130px] "
        } 1xl:max-h-[125px]`}
      >
        {name && (
          <div className="text-left 2xl:text-[18px] xl:text-[18px] 1xl:text-[17px] lg:text-[18px] md:text-[16px] sm:text-[18px] xs:text-[18px] font-[Gilroy-Semibold] text-primary max-w-[100%] grow-0 truncate">
            {name ? name : "Thai Famous Cuisine "}
          </div>
        )}
        {cuisines && (
          <div className="text-left font-[Gilroy] text-grey sm:text-[13px] xs:text-[13px] md:text-[11px] lg:text-[11px] 1xl:text-[10px] xl:text-[14px] 2xl:text-[14px] max-w-[100%] grow-0 truncate">
            {cuisines ? cuisines : "North Indian . Indian . Pure Veg"}
          </div>
        )}

        {type === "POPULAR" && (
          <>
            <div className="flex flex-row gap-1 mt-[0.25rem] mb-[0.25rem]">
              <AiFillStar color={"#FA4F26"} size={16} />
              <AiFillStar color={"#FA4F26"} size={16} />
              <AiFillStar color={"#FA4F26"} size={16} />
              <AiFillStar color={"#fa4f26"} size={16} />
              <AiOutlineStar color={"grey"} size={16} />
            </div>
            <div className="flex flex-row mt-3 justify-between items-center">
              <div className="rounded-md bg-primary w-[50px] h-[17px] text-[11px] font-[Gilroy] text-white">
                Offer
              </div>
              <div className="text-left 2xl:text-[18px] xl:text-[18px] 1xl:text-[16px] lg:text-[14px] md:text-[10px] font-[Gilroy-Semibold] text-primary">
                65% Off
              </div>
            </div>
          </>
        )}

        {type &&
          (type.toUpperCase() === "RESTAURANT" ||
            type.toUpperCase() === "HOME KITCHEN") && (
            <div className="flex flex-row items-center gap-1 w-[100%] mt-2">
              <HiOutlineClock color={"grey"} />
              <div className="text-left 2xl:text-[16px] min-[1280px]:text-[14px] lg:text-[12px] md:text-[11px] md2:text-[11px] font-[Gilroy] text-grey">
                {deliveryTime ? deliveryTime + "" : "N/A min"}
              </div>
              <div className="text-right 2xl:text-[18px] xl:text-[18px] 1xl:text-[16px] lg:text-[14px] md:text-[11px] md2:text-[11px] font-[Gilroy-Semibold] text-primary relative 4xl:right-[-80px] 3xl:right-[-75px] 2xl:right-[-60px] xl:right-[-60px] 1xl:right-[-50px] lg:right-[-75px] md2:right-[-50px] md:right-[-65px] sm:right-[-75px] xs:right-[-75px]">
                {startingPrice
                  ? "$" + parseFloat(startingPrice).toFixed(2)
                  : "$0.00"}
              </div>
            </div>
          )}
        {type === "MENU" && (
          <div className="flex flex-row gap-3 mt-1 mb-3 items-center justify-between">
            <div className="text-left text-[16px] font-[Gilroy-Semibold] text-primary">
              ${price ? parseFloat(price).toFixed(2) : "$ N/A"}
            </div>
            <button
              className={`rounded-full bg-primary mt-2 px-2 text-[25px] leading-none font-[Gilroy] text-white ${
                showNotification ? "opacity-50 pointer-events-none" : ""
              }`}
              onClick={handleAddToCartWithNotification}
            >
              {showNotification ? "Added" : "+"}
            </button>
          </div>
        )}
      </div>
      {showNotification && <Notification message="Product added successfully" />}
    </div>
  );
}
