import {
    deleteRequest,
    getRequest, patchRequest, postFormDataRequest,
    postRequest
} from "../methodCalls";
import {
    deleteUserFavMenuURI, getUserFavListURI, saveUserFavListURI,
    updateUserFavListURI
} from "../endpoints";



export const getUserFavList = (params) => {
    return getRequest(`${getUserFavListURI}`);
}

export const saveUserFavMenu = (payload) => {
    return postFormDataRequest(saveUserFavListURI, payload);
}

export const deleteUserFavMenu = (payload) => {
    const id = payload.id;
    const reqURI = deleteUserFavMenuURI.replace(':id', id);
    return postFormDataRequest(reqURI, payload);
}
