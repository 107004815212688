import { FaBriefcase, FaMapMarkerAlt } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { GoHomeFill } from "react-icons/go";
import { GrClose } from "react-icons/gr";
import { deleteUserAddress } from "../api/address/address";
import { useNavigate } from "react-router-dom";

export const AddressCard = (props: any) => {
  const navigate = useNavigate();
  const { id, address, addressType, city, country, latitude, longitude } =
    props;
  const user: any = localStorage.getItem("user");

  async function handleDeleteAddress() {
    const res = await deleteUserAddress({ id });
    console.log("res delete ", res);
    navigate(0);
  }

  return (
    <div className="w-[100%]">
      <div className="flex flex-col py-4 px-2 items-start justify-start w-[100%] rounded-md bg-[white] max-h-[350px] app-shadow">
        <div className="flex flex-row w-[100%]">
          <div className="basis-[7%] flex justify-center text-white">
            {addressType == "Work" ? (
              <FaBriefcase
                className="p-[3px] rounded-full bg-primary"
                size={24}
              />
            ) : addressType == "Home" ? (
              <GoHomeFill
                className="p-[5px] rounded-full bg-primary"
                size={24}
              />
            ) : (
              <FaMapMarkerAlt
                className="p-[5px] rounded-full bg-primary"
                size={24}
              />
            )}
          </div>

          <div className="basis-[73%] pl-2 ">
            <div className="mb-1 text-left 2xl:text-[15px] xl:text-[15px] 1xl:text-[13px] lg:text-[10px] md:text-[8px] font-[Gilroy-Semibold] text-black">
              {addressType !== "" ? addressType : "Other"}
            </div>
            <div className="text-left 2xl:text-[12px] xl:text-[12px] 1xl:text-[10px] lg:text-[10px] md:text-[8px] font-[Gilroy-Semibold] text-sec-grey">
              Your {addressType} Address
            </div>

            <div className="flex flex-col gap-1 pt-3 w-[60%]">
              <div className="text-left 2xl:text-[12px] xl:text-[12px] 1xl:text-[10px] lg:text-[10px] md:text-[8px] font-[Gilroy-Semibold] text-sec-grey">
                Email
              </div>
              <div
                className="text-left 2xl:text-[12px] xl:text-[12px] 1xl:text-[10px] lg:text-[10px] md:text-[8px] font-[Gilroy-Semibold] text-sec-grey underline leading-2"
              >
                {JSON.parse(user).email}
              </div>
            </div>

            <div className="flex flex-col gap-1 pt-3 w-[60%]">
              <div
                className="text-left 2xl:text-[12px] xl:text-[12px] 1xl:text-[10px] lg:text-[10px] md:text-[8px]
                        font-[Gilroy-Semibold] text-sec-grey"
              >
                Address
              </div>
              <div
                className="text-left 2xl:text-[12px] xl:text-[12px] 1xl:text-[10px] lg:text-[10px] md:text-[8px]
                        font-[Gilroy-Semibold] text-sec-grey leading-2"
              >
                {`${address}, ${city}, ${country}`}
              </div>
            </div>

            <div className="pt-3 w-[60%]">
              <div className="flex flex-row gap-1">
                <div className="basis-1/2 flex flex-col gap-1">
                  <div
                    className="text-left 2xl:text-[12px] xl:text-[12px] 1xl:text-[10px] lg:text-[10px] md:text-[8px]
                        font-[Gilroy-Semibold] text-sec-grey"
                  >
                    Name
                  </div>
                  <div
                    className="text-left 2xl:text-[12px] xl:text-[12px] 1xl:text-[10px] lg:text-[10px] md:text-[8px]
                        font-[Gilroy-Semibold] text-sec-grey underline leading-2"
                  >
                    Umair
                  </div>
                </div>

                <div className="basis-1/2 flex flex-col gap-1">
                  <div
                    className="text-left 2xl:text-[12px] xl:text-[12px] 1xl:text-[10px] lg:text-[10px] md:text-[8px]
                        font-[Gilroy-Semibold] text-sec-grey"
                  >
                    Phone Number
                  </div>
                  <div
                    className="text-left 2xl:text-[12px] xl:text-[12px] 1xl:text-[10px] lg:text-[10px] md:text-[8px]
                        font-[Gilroy-Semibold] text-sec-grey underline leading-2"
                  >
                    *************
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="basis-[15%] underline text-primary text-right 2xl:text-[12px] xl:text-[12px] 1xl:text-[10px] lg:text-[10px] md:text-[8px]">
            <button
              className="rounded-full bg-primary bg-opacity-[20%] border-0 border-none p-1"
              onClick={handleDeleteAddress}
            >
              <IoMdClose className="text-primary" size={20} />
            </button>
          </div>

          <div className="basis-[5%]"></div>
        </div>
      </div>
    </div>
  );
};
