import React, { useState } from "react";
import { BiX, BiStar } from "react-icons/bi";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { IoMdArrowBack } from "react-icons/io";

interface Review {
  name: string;
  rating: number;
  comment: string;
}

interface RestaurantReviewProps {
  onClose: () => void;
}

const RestaurantReview: React.FC<RestaurantReviewProps> = ({ onClose }) => {
  const [showRatingComponent, setShowRatingComponent] = useState(false);
  const [rating, setRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(0);
  const [reviews, setReviews] = useState<Review[]>([
    {
      name: "Abu Bakar Saif",
      rating: 4,
      comment: "Best Food, with great quality, best package",
    },
  ]);

  const handleWriteReviewClick = () => {
    setShowRatingComponent(true);
  };

  const closeRatingComponent = () => {
    setShowRatingComponent(false);
  };

  const onStarClick = (index: number) => {
    setRating(index);
  };

  const onStarHover = (index: number) => {
    setHoverRating(index);
  };

  const addReview = (comment: string) => {
    const newReview: Review = {
      name: "User", 
      rating: rating,
      comment: comment,
    };
    setReviews([...reviews, newReview]);
    closeRatingComponent();
  };

  return (
    <div className="w-[65%]  fixed h-[500px] overflow-y-auto py-12 px-16 rounded-md  insect-0 bg-black z-20 backdrop-blur-sm flex left-64 -bottom-[3vh] mb-10 bg-white ">
      <button className="absolute top-3 right-3" onClick={onClose}>
        <BiX style={{ fontSize: "36px", color: "grey" }} />
      </button>
      {showRatingComponent ? (
        <div className="w-full flex flex-col gap-5">
          <div className="">
            <div className="flex items-center gap-2">
              <button
                className="text-white bg-primary rounded-md py-1 px-2"
                onClick={closeRatingComponent}
              >
                <IoMdArrowBack />
              </button>
              <p className="text-[28px] font-[Gilroy-semibold] text-primary">
                Write a Review Here
              </p>
            </div>

            <div className="flex flex-col items-start gap-2">
              <p className="text-[18px] font-[Gilroy-medium] text-[#8A8A8A]">
                Please rate and review our product
              </p>
              <div>
                {[...Array(5)].map((_, index) => {
                  index += 1;
                  return (
                    <button
                      type="button"
                      key={index}
                      className={`${
                        index <= (hoverRating || rating)
                          ? "text-yellow-500"
                          : "text-gray-400"
                      } hover:text-yellow-500`}
                      onClick={() => onStarClick(index)}
                      onMouseEnter={() => onStarHover(index)}
                      onMouseLeave={() => setHoverRating(rating)}
                    >
                      <span className="text-xl">
                        <BiStar />
                      </span>
                    </button>
                  );
                })}
              </div>
            </div>

            <div className="flex flex-col justify-between items-start">
              <form
                className="w-full max-w-xl pt-2"
                onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                  e.preventDefault();
                  const commentElement = (
                    e.target as HTMLFormElement
                  ).elements.namedItem("comment") as HTMLTextAreaElement | null;
                  if (commentElement && commentElement.value) {
                    addReview(commentElement.value);
                  }
                }}
              >
                <div className="flex items-start border-b border-teal-500 py-2">
                  <textarea
                    name="comment"
                    className="appearance-none bg-transparent w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                    placeholder="Leave your comments"
                    aria-label="Leave your comments"
                  ></textarea>
                </div>
                <div className="mt-5">
                  <button
                    type="submit"
                    className="inline-block px-6 py-2 text-xs font-medium leading-6 text-center text-white uppercase transition bg-primary rounded shadow ripple hover:shadow-lg focus:outline-none"
                  >
                    Add Review
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div className=" font-[Gilroy] justify-center items-center w-full ">
          <div className="relative text-left">
            <h2 className="text-4xl font-bold mb-1 ">High Peak Kitchen</h2>
          </div>
          <div className="flex">
            <p className="text-grey-600 font-semibold text-[#000000]">
              Saddar Bazar, Rawalpindi
            </p>
          </div>
          <div className="flex mt-2 justify-between text-center  border-grey ">
            <div className="flex flex-row gap-3 mt-3">
              <div className="flex flex-row gap-1 mt-[0.25rem] mb-[0.25rem]">
                <AiFillStar color={"#FA4F26"} size={16} />
                <AiFillStar color={"#FA4F26"} size={16} />
                <AiFillStar color={"#FA4F26"} size={16} />
                <AiFillStar color={"#fa4f26"} size={16} />
                <AiOutlineStar color={"grey"} size={16} />
              </div>
              <p className="text-grey-600">(8 reviews)</p>
            </div>
            <div className="flex">
              <button
                className="bg-primary text-white text-sm rounded-md p-2"
                onClick={handleWriteReviewClick}
              >
                Write a Review
              </button>
            </div>
          </div>
          <div className="flex flex-col mt-5 gap-4">
            {reviews.map((review, index) => (
              <div key={index} className="flex gap-5 border-t pt-5 ">
                <div className="h-full">
                  <img
                    src="https://images.unsplash.com/photo-1692839929461-b3b30e36ef70?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWV"
                    alt="avatar"
                    className="h-14 w-14 rounded-full"
                  />
                </div>
                <div className="flex flex-col items-start">
                  <div className="w-full gap-5 flex">
                    <h4 className="text-[#000000] font-semibold mt-1">
                      {review.name}
                    </h4>
                    <div className="flex flex-row gap-1 mt-[0.40rem] mb-[0.25rem]">
                      {[...Array(review.rating)].map((_, i) => (
                        <AiFillStar key={i} color={"#FA4F26"} size={16} />
                      ))}
                      {[...Array(5 - review.rating)].map((_, i) => (
                        <AiOutlineStar key={i} color={"grey"} size={16} />
                      ))}
                    </div>
                  </div>
                  <div className="flex ">
                    <p className="text-sm text-[#000000] mt-1">
                      {review.comment}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default RestaurantReview;
