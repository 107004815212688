import { CuisineCard } from "../components/CuisineCard";
import { RestaurantCard } from "../components/RestaurantCard";
import { PopularMenuCard } from "../components/PopularMenuCard";
import { AiOutlineSearch } from "react-icons/ai";
import { useEffect, useState } from "react";
import { getAllRestaurants } from "../api/restaurant/restaurant";
import { getCuisineList } from "../api/misc/misc";
import { ImSpinner3 } from "react-icons/im";
import { AbstractCard } from "../components/AbstractCard";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import PlayStore from "../components/PlayStore";
import CuisineSwiper from "../components/CusinieMenu";
import CuisineMenu from "../components/CusinieMenu";

export function Home() {
  const navigate = useNavigate();
  const i: Array<number> = [1, 2, 3, 4, 5];
  const [restaurants, setRestaurants] = useState<any[]>([]);
  const [cuisines, setCuisines] = useState<any[]>([]);
  const [cuisineLoading, setCuisineLoading] = useState<boolean>(true);
  const [restLoading, setRestLoading] = useState<boolean>(true);

  // useEffect(()=>{
  //     const token = localStorage.getItem("accessToken")
  //     const user = localStorage.getItem("user")
  //     if(!token && !user){
  //         navigate('/login')
  //     }
  // },[])

  useEffect(() => {
    if (!(restaurants.length > 0)) {
      (async () => {
        const rL = localStorage.getItem("r%L");
        if (rL != null) {
          setRestaurants(JSON.parse(rL));
          setRestLoading(false);
        }
        const res = await getAllRestaurants();
        if (res.request.status !== 200) {
          let err = res?.response?.data?.error
            ? JSON.stringify(res.response.data.error)
            : "Error getting Restaurants";
          setRestLoading(true);
        } else {
          const newList = res?.data?.records?.data;
          setRestaurants(newList);
          localStorage.setItem("r%L", JSON.stringify(newList));
          setRestLoading(false);
        }
      })();
    }
  }, []);

  useEffect(() => {
    if (!(cuisines.length > 0)) {
      (async () => {
        const cL = localStorage.getItem("c%L");
        if (cL != null) {
          setCuisines(JSON.parse(cL));
          setCuisineLoading(false);
        }
        const res = await getCuisineList();
        if (res.request.status !== 200) {
          let err = res.response?.data?.error
            ? JSON.stringify(res?.response?.data?.error)
            : "Error getting Restaurants";
          setCuisineLoading(true);
        } else {
          const newList = res?.data?.records?.data;
          setCuisines(newList);
          localStorage.setItem("c%L", JSON.stringify(newList));
          setCuisineLoading(false);
        }
      })();
    }
  }, []);

  return (
    <div className="bg-[white] w-[100%] pl-0 pr-0">
      <div className="w-[85%] m-auto">
        <div className="text-[28px] mb-2 font-[Gilroy-Semibold] text-left pt-10">
          Cuisine
        </div>
        <CuisineMenu />

        <div
          className="border border-1 border-primary rounded-md flex flex-start 4xl:w-[40%] 3xl:w-[40%] 2xl:w-[40%] xl:w-[40%] 1xl:w-[40%] lg:w-[50%] md2:w-[60%] md:w-[70%] sm:w-[100%] xs:w-[100%] mt-10 items-center text-[14px] font-[Gilroy]">
          <input
            className="p-3 w-[90%] rounded-md outline-none"
            type="text"
            placeholder="Search your food"
          />
          <div className="rounded-md bg-primary min-[1280px]:w-[12%] h-[100%] p-3 cursor-pointer flex flex-row justify-center">
            <AiOutlineSearch
              size={24}
              color={"white"}
              onClick={() => {
                navigate("/restaurants");
              }}
            />
          </div>
        </div>
        <div className="flex justify-between mt-14 items-baseline">
          <div className="text-[28px] font-[Gilroy-Semibold] text-left">
            Restaurants
          </div>
        </div>

        {restLoading ? (
          <div className="grid grid-cols-1 mt-5 justify-items-center">
            <p className="text-2xl font-medium">No Restaurant Registered Yet</p>
          </div>
        ) : (
          <div className="grid 4xl:grid-cols-6 3xl:grid-cols-5 2xl:grid-cols-5 xl:grid-cols-5 1xl:grid-cols-5 lg:grid-cols-4 md2:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-3 mt-3 justify-center">
            {restaurants.map((rest, i) => (
              <>
                <RestaurantCard
                  key={i}
                  id={rest.id}
                  type={rest?.business_type.toUpperCase()}
                  name={rest?.business_name}
                  address={rest?.restaurant_address}
                  cuisines={rest?.cuisine.cuisine_name}
                  menu={rest?.restaurant_menue}
                  image={rest?.business_image}
                  startingPrice={rest?.starting_price}
                  deliveryTime={rest?.ordr_delivery_time}
                />
              </>
            ))}
          </div>
        )}

        <div className="flex justify-between mt-20 items-baseline">
          <div className="text-[28px] font-[Gilroy-Semibold] text-left">
            Most Popular
          </div>
          <div className="text-[16px] font-[Gilroy] text-left text-primary underline">
            View all
          </div>
        </div>

        <div className="grid 4xl:grid-cols-6 3xl:grid-cols-5 2xl:grid-cols-5 xl:grid-cols-5 1xl:grid-cols-5 lg:grid-cols-4 md2:grid-cols-4 md:grid-cols-3 gap-3 mt-3 sm:grid-cols-2 justify-center">
          {i.map((i) => (
            <PopularMenuCard id={i} />
          ))}
        </div>

        <div className="flex justify-between mt-20 items-baseline">
          <div className="text-[28px] font-[Gilroy-Semibold] text-left">
            Most Sales
          </div>
          <div className="text-[16px] font-[Gilroy] text-left text-primary underline">
            View all
          </div>
        </div>

        <div className="grid 4xl:grid-cols-6 3xl:grid-cols-5 2xl:grid-cols-5 xl:grid-cols-5 1xl:grid-cols-5 lg:grid-cols-4 md2:grid-cols-4 md:grid-cols-3 gap-3 sm:grid-cols-2 mt-3 justify-center">
          {i.map((i) => (
            // <div className="flex-none shrink-0">
            <PopularMenuCard id={i} />
            // </div>
          ))}
        </div>

        <PlayStore />
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
}

// export default Home;
