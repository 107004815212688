import { Field } from "formik";
import { AiOutlineMinus } from "react-icons/ai";
import { AiOutlinePlus } from "react-icons/ai";
import { useState } from "react";
import { deleteCartItem, updateCartItem } from "../../api/cart/cart";

export const CartItem = (props: any) => {
  const {
    id,
    quantity,
    menuId,
    userId,
    userVariants,
    menuItem,
    onUpdate,
    setUpdateItemId,
  } = props;
  const [count, setCount] = useState(quantity ? parseInt(quantity) : 0);

  console.log(
    "propssss::: ",
    id,
    quantity,
    menuId,
    userId,
    userVariants,
    menuItem
  );

  return (
    <div
      className="bg-[white] max-w-[100%] max-h-[75px]
            flex flex-row justify-center rounded-2xl drop-shadow-md"
    >
      <div className="flex flex-row grow-0 w-[100%] p-5 pt-2 pb-2 justify-start items-start gap-1 accent-primary">
        <div className="basis-[5%]">
          <input id={id} name={id} type="radio" checked />
        </div>
        <div className="basis-[60%] ma grow-0">
          <div className="flex flex-col max-w-[100%] grow-0 truncate">
            <div className="max-w-[180px] gap-2 font-[Gilroy-Semibold] text-[16px] truncate text-left">
              <label htmlFor="firstName">
                {menuItem ? menuItem.item_name : "N/A"}
              </label>
            </div>
            <div className="max-w-[180px] gap-2 font-[Gilroy] text-[12px] truncate text-left">
              <label htmlFor="firstName">
                {menuItem ? menuItem.description : "Size: N/A [10 inch]"}
              </label>
            </div>
            <div className=" flex items-center w-[100%] gap-2 font-[Gilroy-Semibold] text-[14px] text-primary">
              <label htmlFor="firstName">
                $
                {menuItem
                  ? (parseFloat(menuItem.regular_price) * count).toFixed(2)
                  : "N/A"}
              </label>
            </div>
          </div>
        </div>

        <div className="basis-[35%]">
          <div className="flex flex-row w-[100px] items-center">
            <button
              className="rounded-md bg-primary bg-opacity-20 border border-primary border-1 pt-0 pl-2 pr-2 text-primary text-[16px] h-[32px] "
              onClick={async (e) => {
                e.stopPropagation();
                if (count > 1) {
                  setCount(count - 1);
                  setUpdateItemId({
                    id: id,
                    quantity: count - 1,
                  });
                  await updateCartItem({
                    id: id,
                    restaurant_menue_id: menuId,
                    quantity: count - 1,
                    menue_varient_id: [],
                  });
                } else {
                  setCount(0);
                  await deleteCartItem({ id: id });
                }
                onUpdate();
              }}
            >
              <AiOutlineMinus />
            </button>
            <div className="text-[black] font-[Gilroy-Semibold] text-[18px] leading-3 text-center min-w-[2rem] m-auto">
              {count}
            </div>
            <button
              className="rounded-md bg-primary border border-primary border-1 pt-0 pl-2 pr-2 text-white text-[16px] h-[32px] "
              onClick={async (e) => {
                e.stopPropagation();
                setCount(count + 1);
                setUpdateItemId({
                  id: id,
                  quantity: count + 1,
                });
                await updateCartItem({
                  id: id,
                  restaurant_menue_id: menuId,
                  quantity: count + 1,
                  menue_varient_id: [],
                });
                onUpdate();
              }}
            >
              <AiOutlinePlus />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
