import {LoginForm} from "../components/LoginForm";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import OrderFailed from "../assets/order-failed.png";
import {confirmOrderPayment} from "../api/order/order";
import Footer from "../components/Footer";

export const OrderFailure = () =>{
    const navigate = useNavigate();
    const [payErr, setPayErr] = useState<any>(null);

    useEffect(()=>{
        const token = localStorage.getItem("accessToken")
        const user = localStorage.getItem("user")
        if(!token && !user){
            navigate('/login')
        }
    },[])


    const handleOrderPayment = async () =>{
        const res = await confirmOrderPayment()
        if(res?.request?.status !== 200){
            setPayErr(
                {
                    success: false,
                    msg: res?.response?.data?.records?.error
                })
            setTimeout(()=>{
                setPayErr(null)
            },2000)

        }
        else{
            setPayErr(
                {
                    success: true,
                    msg: "Payment Successfully Completed!"
                })
            setTimeout(()=>{
                setPayErr(null)
                navigate('/order_success')
            },2000)
        }
    }

    return(
        <div className="rest-home bg-[white] h-[88vh] w-[100vw] pt-10 px-0">
            <div className="w-[50%] m-auto pt-[1rem] border border-primary rounded-xl">
                <div className="flex flex-row justify-center">
                    <img src={OrderFailed} />
                </div>

                <div className="flex flex-row gap-5 justify-center py-14 w-[70%] m-auto font-[Gilroy-Semibold] text-white">
                        <button
                            onClick={handleOrderPayment}
                            className="basis-1/2 px-3 rounded-xl bg-primary">
                            Try Again
                        </button>
                        <button
                            onClick={()=>{
                                navigate(`/restaurants`)
                            }}
                            className="basis-1/2 py-3 rounded-xl bg-primary bg-opacity-30 text-primary">
                            Order something else!
                        </button>
               </div>
                {
                    payErr ?
                        <div className={`font-[Gilroy] text-[16px] text-left p-2 mt-10 w-[40%] m-auto ${payErr.success?  "bg-[green] text-white" : "text-primary bg-primary"} bg-opacity-30`}>
                            {payErr?.msg}
                        </div>
                        : null
                }

            </div>
            <Footer />
        </div>
    );
}
