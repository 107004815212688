import {BaseModal} from "./BaseModal";
import {Field, FormikProvider, useFormik, ErrorMessage} from "formik";
import {useState} from "react";
import {addItemToCart} from "../api/cart/cart";
import {Link, useNavigate} from "react-router-dom";
import { AiOutlineGoogle } from "react-icons/ai";
import {FaFacebook} from "react-icons/fa";
import loginbackground from "../assets/login.png"
import {getLoginUser} from "../api/auth/auth";
import * as Yup from 'yup'
import {ImSpinner3} from "react-icons/im";


export const LoginForm = () => {
    const navigate = useNavigate();
    const [err, setErr] = useState(null);
    const [apiLoading, setApiLoading] = useState<boolean>(false);

    const loginSchema = Yup.object().shape({
        email: Yup.string().email().required('Invalid input entered'),
        password: Yup.string().required('Required').min(3, 'Too Short!'),
    })

    const checkPreAddedCartItems = async () => {
        if(localStorage.getItem("u%c%")){
            let items:any = localStorage.getItem("u%c%")
            items = JSON.parse(items);
            for(let payload of items){
                const res = await addItemToCart(payload)
                if(res?.request?.status !== 200){
                    setErr(res?.response?.data.records)
                    setTimeout(()=>{
                        setErr(null)
                    },2000)
                }
            }
            localStorage.removeItem("u%c%")
        }
    }

    const handleLogin = async (payload:any) => {
        setApiLoading(true)
        const res = await getLoginUser(payload)
        if(res?.request?.status !== 200){
            setApiLoading(false)
            setErr(res?.response?.data.message)
            setTimeout(()=>{
                setErr(null)
            },2000)
        }
        else{
            localStorage.setItem("user", JSON.stringify(res.data.records))
            let token =res.data.records.token;
            localStorage.setItem("accessToken", token)
            if(res.data.records.user_status == "Pending"){
                sessionStorage.setItem("userStatusPending", payload.password)
            }
            await checkPreAddedCartItems()
            if(res.data.records.user_status == "Pending"){
                navigate("/restaurants");                
            }
            else
            navigate("/login");
        }

    }

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: loginSchema,
        onSubmit: values => {
            // alert(JSON.stringify(values, null, 2));
            // alert(JSON.stringify(values, null, 2));
            handleLogin(values);
        },
    });

    const { touched, handleSubmit, values, errors, setFieldValue } = formik;

    return(
      <BaseModal auth={true}>
          <div className="font-[Gilroy-Semibold] text-[40px]">
              Sign In
          </div>
          <div className="font-[Gilroy] text-[14px] text-sec-grey">
              <span>
                  New User?
              </span>
              &nbsp;              &nbsp;
              <span className="font-[Gilroy-Semibold] text-primary">
                  <Link to="/register">
                      Create an Account
                  </Link>
              </span>
          </div>

          <div className="mt-5 mb-5">
              <hr className="w-[100%] h-[0.1px] bg-grey border-0 rounded bg-grey"/>
          </div>

          <FormikProvider value={formik}>
              {/*{({ touched, handleSubmit, values, errors }) => (*/}
                  <form onSubmit={formik.handleSubmit}>

                  <div className="flex flex-col items-left text-left font-[Gilroy] text-[14px] text-black">
                      <label htmlFor="email">Email</label>
                      <Field
                          className="font-[Gilroy] text-[14px] text-sec-grey h-[35px] outline-none focus:outline-none border-b border-underline-c"
                          id="email" name="email" placeholder="Enter your Email" type="email" />
                      <div className="font-[Gilroy] text-[10px] text-primary pb-2">
                          <ErrorMessage name="email" component="div" className="error" />
                      </div>
                  </div>

                  <div className="flex flex-col items-left text-left font-[Gilroy] text-[14px] text-black">
                      <label htmlFor="password">Password</label>
                      <Field
                          className="font-[Gilroy] text-[14px] text-sec-grey h-[35px] outline-none focus:outline-none border-b border-underline-c"
                          id="password" name="password" placeholder="min. 8 characters" type="password"
                      />
                      <div className="font-[Gilroy] text-[10px] text-primary pb-2">
                          <ErrorMessage name="password" component="div" className="error" />
                      </div>
                  </div>

                  <div className="text-primary text-right text-[12px] font-[Gilroy] mt-2 mb-5" >
                    Forgot your password?
                  </div>

                  <div className="mb-4">
                      {
                          err?
                              <div className="font-[Gilroy] text-[16px] text-left text-primary p-2 w-[100%] bg-primary bg-opacity-20 ">
                                  {err}
                              </div>
                              :
                              (apiLoading?
                                      <div className="grid grid-cols-1 mt-5 justify-items-center">
                                          <ImSpinner3 size={20} color="light" />
                                      </div>
                                      :
                                      <button
                                          className="w-[100%] bg-primary text-white font-[Gilroy] rounded-full h-[40px]"
                                          type="submit">
                                          Continue
                                      </button>
                              )

                      }
                  </div>

                  {/* <div className="flex flex-row gap-2 items-center mt-8 mb-4">
                      <hr className="w-[100%] h-[0.1px] bg-grey border-0 rounded bg-grey"/>
                      Or
                      <hr className="w-[100%] h-[0.1px] bg-grey border-0 rounded bg-grey"/>
                  </div>

                  <button
                      className="w-[100%] flex items-center justify-center bg-white border border-1 border-primary text-primary font-[Gilroy] rounded-full h-[40px]"
                      type="submit">
                      <span>
                          <AiOutlineGoogle size={22}/>
                      </span>
                      &nbsp;
                      Sign in with Google
                  </button> 

                  <br/>
                  <button
                      className="w-[100%] flex items-center justify-center bg-white border border-1 border-blue text-blue font-[Gilroy] rounded-full h-[40px]"
                      type="submit">
                      <span>
                          <FaFacebook size={22}/>
                      </span>
                      &nbsp;
                      Sign in with Facebook
                  </button> */}
              </form>
              {/*)}*/}
          </FormikProvider>

      </BaseModal>
    );
}
