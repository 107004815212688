import React from 'react';
import logo from "../assets/nav-logo.png";
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className="bg-primary text-white py-20 px-10 mt-12 border border-grey">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 text-center md:text-left gap-20 md:gap-10 lg:gap-20 sm:gap-10 xs:gap-5">
        <div className="mb-4 md:mb-0">
          <img src={logo} alt="Logo" height={90} width={90} className="mx-auto md:mx-0 bg-white" />
          <p className="text-gray-400 text-md mt-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Corporis architecto qui debitis sed repellat iusto officiis?</p>
        </div>
        <div className="mb-4 md:mb-0">
          <h5 className="font-bold uppercase mb-5">Legal Safety</h5>
          <Link to="/privacy-policy" style={{ borderBottom: '1px solid white' ,width:"max-content"}} className="block mb-3">Privacy Policy</Link>
          <Link to="/cookies-policy" style={{ borderBottom: '1px solid white' ,width:"max-content"}} className="block mb-3">Cookies Policy</Link>
          <Link to="/terms-of-use" style={{ borderBottom: '1px solid white' ,width:"max-content"}} className="block">Terms of Use</Link>
        </div>
        <div className="mb-4 md:mb-0">
          <h5 className="font-bold uppercase mb-5">Work With Us</h5>
          <Link to="/career" style={{ borderBottom: '1px solid white' ,width:"max-content"}} className="block mb-3">Career Opportunities</Link>
          <Link to="/partner" style={{ borderBottom: '1px solid white' ,width:"max-content"}} className="block mb-3">Partner with Us</Link>
          <Link to="/invest" style={{ borderBottom: '1px solid white' ,width:"max-content"}} className="block">Investor Relations</Link>
        </div>
        <div>
          <h5 className="font-bold uppercase mb-5">Help and Support</h5>
          <Link to="/faq" style={{ borderBottom: '1px solid white' ,width:"max-content"}} className="block mb-3">FAQ</Link>
          <Link to="/contact" style={{ borderBottom: '1px solid white' ,width:"max-content"}} className="block mb-3">Contact Us</Link>
          <Link to="/support" style={{ borderBottom: '1px solid white' ,width:"max-content"}} className="block">Support Center</Link>
        </div>
      </div>
    </div>
  );
}

export default Footer;
