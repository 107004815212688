import React, { useState } from "react";
import { HiOutlineClock } from "react-icons/hi";
import { AiFillStar } from "react-icons/ai";
import RestaurantReview from "../components/RestaurantReview";

export const RestaurantInfoCard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="rest-info-card relative flex gap-3 items-center justify-between p-3 rounded-lg app-shadow">
      <div className="basis-[30%] rounded-full border border-primary border-1 p-2 3xl:text-[15px] 2xl:text-[15px] 1xl:text-[15px] xl:text-[15px] lg:text-[13px] sm:text-[8px] xs:text-[8px] font-[Gilroy-Semibold] text-primary">
        Free Delivery
      </div>

      <div
        className="flex flex-row items-center justify-center p-2 gap-2 basis-[30%] rounded-full border border-primary border-1"
        style={{ cursor: "pointer" }}
      >
        <HiOutlineClock color={"grey"} size={20} />
        <div className="text-left 3xl:text-[15px] 2xl:text-[15px] 1xl:text-[15px] xl:text-[15px] lg:text-[12px] md2:text-[8px] md:text-[8px] sm:text-[8px] xs:text-[8px] font-[Gilroy] text-grey">
          30-35 min
        </div>
      </div>

      <div
        className="flex basis-[22%] p-2 pr-[1.5rem] pl-[1.5rem] items-center justify-end gap-2 rounded-full border border-primary border-1
            3xl:text-[15px] 2xl:text-[15px] 1xl:text-[15px] xl:text-[15px] lg:text-[12px] md2:text-[8px] md:text-[8px] sm:text-[8px] xs:text-[8px]"
        style={{ cursor: "pointer" }}
        onClick={toggleModal}
      >
        <div className="rounded-full bg-primary p-1">
          <AiFillStar color={"white"} />
        </div>
        <div className="font-[Gilroy-Semibold]">4.5</div>
        <div className="text-sec-grey font-[Gilroy] text-[14px]">(200+)</div>
      </div>

      {isModalOpen && <RestaurantReview onClose={toggleModal} />}
    </div>
  );
};
