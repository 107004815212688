import {
    getRequest,
} from "../methodCalls";

import {
    getAllRestaurantsURI, getRestaurantByIdURI,
} from "../endpoints";

export const getAllRestaurants = (params) => {
    return getRequest(`${getAllRestaurantsURI}`);
}

export const getRestaurantById = (params) => {
    const id = params.id;
    const reqURI = getRestaurantByIdURI.replace(':id', id);
    return getRequest(`${reqURI}`);
}
