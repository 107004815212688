import { LoginForm } from "../components/LoginForm";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import orderSuccess from "../assets/order-success.png";
import Footer from "../components/Footer";

export const OrderSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    const user = localStorage.getItem("user");
    if (!token && !user) {
      navigate("/login");
    }
  }, []);

  return (
    <div className="rest-home bg-[white] h-[88vh] w-[100vw] pt-10 px-0">
      <div className="w-[50%] m-auto pt-[1rem] border border-primary rounded-xl">
        <div className="flex flex-row justify-center">
          <img src={orderSuccess} />
        </div>
        <div className="flex flex-row gap-5 justify-center py-14 w-[70%] m-auto font-[Gilroy-Semibold] text-white">
          <button className="basis-1/2 px-3 rounded-xl bg-primary" onClick={() => {
            navigate('/order-track');
          }}>
            Track my Order
          </button>
          <button
            onClick={() => {
              navigate(`/restaurants`);
            }}
            className="basis-1/2 py-3 rounded-xl bg-primary bg-opacity-30 text-primary"
          >
            Order something else!
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};
