import {SignupForm} from "../components/SignupForm";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

export const SignupScreen = () =>{
    const navigate = useNavigate();

    useEffect(()=>{
        const token = localStorage.getItem("accessToken")
        const user = localStorage.getItem("user")
        if(token && user){
            navigate('/dashboard')
        }
    },[])

    return(
        <div className="rest-home bg-[white] w-[100vw] p-0 pl-0 pr-0">
            {/*<div className="w-[85%] m-auto pt-[1rem]">*/}

               <SignupForm/>

            {/*</div>*/}
        </div>
    );
}
