import {BaseModal} from "./BaseModal";
import {GoHomeFill} from "react-icons/go";
import {ErrorMessage, Field, FormikProvider, useFormik} from "formik";
import * as Yup from "yup";
import {getLoginUser} from "../api/auth/auth";
import {saveUserAddress} from "../api/address/address";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

export const AddNewAddressModal = ({handleClose}:any) => {
    const navigate = useNavigate();
    const [latitude, setLatitude] = useState<any>(null)
    const [longitude, setLongitude] = useState<any>(null)
    const [err, setErr] = useState<any>(null)

    useEffect(()=>{
        window.navigator.geolocation.getCurrentPosition(function (pos) {
            const lat = pos.coords.latitude;
            const long = pos.coords.longitude;
            setLatitude(lat);
            setLongitude(long);
        })
    }, [])

    const addressSchema = Yup.object().shape({
        address: Yup.string().required('Invalid input entered'),
        street: Yup.string().required('Required').min(3, 'Too Short!'),
        city    : Yup.string().required('Required').min(3, 'Too Short!'),
        country    : Yup.string().required('Required').min(2, 'Too Short!'),
        address_type    : Yup.string().required('Required').min(3, 'Too Short!'),
    })


    const handleAddAddress = async (raw:any) => {
        const payload = {
            address: raw.address,
            address_type: [raw.address_type],
            city: [raw.city],
            country: [raw.country],
            latitude: latitude,
            longitude: longitude
        }
        const res = await saveUserAddress(payload)
        console.log(" adress res", res)
        if(res?.request?.status !== 200){
            setErr(res?.response?.data.message)
            setTimeout(()=>{
                setErr(null)
            },2000)
        }
        else{
            handleClose();
            navigate(0);
            // localStorage.setItem("user", JSON.stringify(res.data.records))
            // let token =res.data.records.token;
            // localStorage.setItem("accessToken", token)
            // navigate("/dashboard");
        }

    }

    const formik = useFormik({
        initialValues: {
        },
        validationSchema:addressSchema,
        onSubmit: (values:any) => {
            // alert(JSON.stringify({...values, latitude: lat} , null, 2));
            handleAddAddress(values);

        },
    });

    return(
        <BaseModal
            handleClose={handleClose}>

            <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                    <div className="main pt-[-5] ">
                        <div className="flex flex-row gap-5">
                            <div className="text-white">
                                <GoHomeFill className="p-[3px] rounded-xl bg-primary" size={40} />
                            </div>
                            <div>
                                <div className="font-[Gilroy-Semibold] text-[16px] text-left">
                                    Home
                                </div>
                                <div className="font-[Gilroy] text-[12px] text-left">
                                    Your Home Address
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col gap-2 pt-5 w-[80%]">
                            <div className="flex flex-col items-left text-left font-[Gilroy] text-[14px] text-black">
                                <label htmlFor="address">Home Address</label>
                                <Field
                                    className="font-[Gilroy] text-[14px] text-sec-grey h-[35px] outline-none focus:outline-none border-b border-underline-c"
                                    id="address" name="address" placeholder="Enter your address" type="address" />
                                <div className="font-[Gilroy] text-[10px] text-primary pb-2">
                                    <ErrorMessage name="address" component="div" className="error" />
                                </div>
                            </div>

                            <div className="pt-5 pb-4">
                                <div className="flex flex-row gap-5">
                                    <div className="basis-1/2 flex flex-col gap-2 ">
                                        <div className="flex flex-col items-left text-left font-[Gilroy] text-[14px] text-black">
                                            <label htmlFor="street">Street Number</label>
                                            <Field
                                                className="font-[Gilroy] text-[14px] text-sec-grey h-[35px] outline-none focus:outline-none border-b border-underline-c"
                                                id="street" name="street" placeholder="Ex. Street # 4" type="string" />
                                            <div className="font-[Gilroy] text-[10px] text-primary pb-2">
                                                <ErrorMessage name="street" component="div" className="error" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="basis-1/2 flex flex-col gap-2 ">
                                        <div className="flex flex-col items-left text-left font-[Gilroy] text-[14px] text-black">
                                            <label htmlFor="city">City</label>
                                            <Field
                                                className="font-[Gilroy] text-[14px] text-sec-grey h-[35px] outline-none focus:outline-none border-b border-underline-c"
                                                id="city" name="city" placeholder="Ex. New York" type="string" />
                                            <div className="font-[Gilroy] text-[10px] text-primary pb-2">
                                                <ErrorMessage name="city" component="div" className="error" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="pb-4">
                                <div className="flex flex-row gap-5">
                                    <div className="basis-1/2 flex flex-col gap-2 ">
                                        <div className="flex flex-col items-left text-left font-[Gilroy] text-[14px] text-black">
                                            <label htmlFor="country">Country</label>
                                            <Field
                                                className="font-[Gilroy] text-[14px] text-sec-grey h-[35px] outline-none focus:outline-none border-b border-underline-c"
                                                id="country" name="country" placeholder="Ex. Japan" type="string" />
                                            <div className="font-[Gilroy] text-[10px] text-primary pb-2">
                                                <ErrorMessage name="country" component="div" className="error" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="basis-1/2 flex flex-col gap-2 ">
                                        <div className="flex flex-col items-left text-left font-[Gilroy] text-[14px] text-black">
                                            <label htmlFor="address_type">Address Type</label>
                                            <Field
                                                className="font-[Gilroy] text-[14px] text-sec-grey h-[35px] outline-none focus:outline-none border-b border-underline-c"
                                                id="address_type" name="address_type" placeholder="Ex. Home, Office or Other" type="string" />
                                            <div className="font-[Gilroy] text-[10px] text-primary pb-2">
                                                <ErrorMessage name="address_type" component="div" className="error" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {
                            err?
                                <div className="font-[Gilroy] text-[16px] text-left text-primary p-2 w-[100%] bg-primary bg-opacity-20 ">
                                    {err}
                                </div>
                                :
                                <div className="group relative">
                                    <button className={`mt-5 py-2 px-32 p-2 rounded-full bg-primary text-white font-[Gilroy-Semibold] 
                                    ${latitude ? "" : "cursor-not-allowed"}`} type="submit">
                                        Add
                                    </button>
                                    <span
                                    className="pointer-events-none absolute top-10 left-0
                                    mt-5 pr-20 pl-20 p-2 font-[Gilroy] text-[10px]
                                     opacity-0 transition-opacity group-hover:opacity-100"
                                    >
                                    Add all fields and allow the location to add the address!
                                  </span>
                                </div>
                        }
                    </div>
                </form>
            </FormikProvider>
        </BaseModal>
    )
}
