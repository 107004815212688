import React from 'react';
import user from "../assets/faq.png"

const FAQs = () => {
  const faqs = [
    {
      question: "Is there a free trial available?",
      answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste maxime voluptatem facilis, vel nam quasi, dolorum iusto sequi dolorum iusto sequi dolorum iusto sequi"
    },
    {
      question: "How does billing work?",
      answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste maxime voluptatem facilis, vel nam quasi, dolorum iusto sequi dolorum iusto sequi dolorum iusto sequi"
    },
    {
      question: "What is your cancellation policy?",
      answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste maxime voluptatem facilis, vel nam quasi, dolorum iusto sequi dolorum iusto sequi dolorum iusto sequi"
    },
    {
      question: "Is there a free trial available?",
      answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste maxime voluptatem facilis, vel nam quasi, dolorum iusto sequi dolorum iusto sequi dolorum iusto sequi"
    },
    {
      question: "How does billing work?",
      answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste maxime voluptatem facilis, vel nam quasi, dolorum iusto sequi dolorum iusto sequi dolorum iusto sequi"
    },
    {
      question: "What is your cancellation policy?",
      answer: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste maxime voluptatem facilis, vel nam quasi, dolorum iusto sequi dolorum iusto sequi dolorum iusto sequi"
    }
  ];

  return (
    <>
    <div className="mt-20 gap-10 items-center flex flex-col">
      <div className="flex flex-col justify-between gap-4">
        <h1 className="text-4xl font-bold">Frequently Asked Questions</h1>
        <p className="text-grey text-[16px]">
          Have Questions? We're here to help
        </p>
        <div>
          <input
            className="font-[Gilroy] font-thin text-[14px] text-primary border border-1 border-primary rounded-md p-3 w-[80%] h-[40px]"
            type="text"
            placeholder="Search"
          />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 p-10 w-[85%]">
        {faqs.map((faq, index) => (
          <div key={index} className="flex flex-col mb-2">
            <h1 className="text-[17px] mb-3 text-center w-[90%] font-medium">{faq.question}</h1>
            <p className="text-grey text-[14px] w-[85%] text-center">{faq.answer}</p>
          </div>
        ))}
      </div>
    </div>
    <div className='bg-[#F9FAFB] w-[85%] mx-auto py-10 px-8 rounded-md'>
        <div className='flex flex-col justify-center items-center gap-4 '>
            <img src={user} alt="" />
            <p className='text-xl font-medium'>Still have questions?</p>
            <h6 className='text-lg font-bold'>Can’t find the answer you’re looking for? Please chat to our friendly team.</h6>
            <button className='text-[#FFFFFF] text-base font-semibold bg-primary py-3 px-10 rounded-md'>Get in touch</button>
        </div>
    </div>
    </>
  );
};

export default FAQs;
