import { CuisineCard } from "../components/CuisineCard";
import { RestaurantCard } from "../components/RestaurantCard";
import  {NearRestaurantCard}  from "../components/NearRestaurantCard"
import { PopularMenuCard } from "../components/PopularMenuCard";
import { AiOutlineSearch } from "react-icons/ai";
import { useEffect, useState } from "react";
import { getAllRestaurants } from "../api/restaurant/restaurant";
import { getCuisineList } from "../api/misc/misc";
import { ImSpinner3 } from "react-icons/im";
import { AbstractCard } from "../components/AbstractCard";
import Footer from "../components/Footer";
import { TbArrowsCross } from "react-icons/tb";
import dashboardbanner from "../assets/home-banner.png";
import guestHome from "../assets/guest-home.png";
import { MdArrowBackIosNew } from "react-icons/md";
import { CuisineCardRound } from "../components/CuisineCardRound";
// import Apple from "../assets/apple.png";
// import Banana from "../assets/banana.png";
// import Stack1 from "../assets/stack-1.png";
// import Stack2 from "../assets/stack-2.png";
// import Stack3 from "../assets/stack-3.png";
import Vector from "../assets/Vector.svg";
import Stack from "../assets/stack.png";
import Sandwich from "../assets/mind-1.png";
import Thali from "../assets/mind-2.png";
import Egg from "../assets/mind-3.png";
import Rolls from "../assets/mind-4.png";
import Salad from "../assets/mind-5.png";
import Biryani from "../assets/mind-6.png";

import { useNavigate } from "react-router-dom";
import PlayStore from "../components/PlayStore";
import FAQs from "../components/FAQ";

export function GuestHome() {
  return (
    <div className="bg-white">
      <div className="container">
        <div className="flex py-10 items-start justify-between">
          <div className="w-[50%] pt-20 pl-28">
            <div className="flex gap-1 py-4">
              <h1 className="text-6xl font-bold text-start leading-12">
                Premium
                <span className="text-[#FA4F26] text-6xl font-bold mx-4">
                  quality
                </span>
                <br />
                Food for your <br />
                <span className="text-[#FA4F26] text-6xl font-bold">
                  healthy & Daily Life
                </span>
              </h1>
            </div>
          </div>

          <div className="w-[50%]">
            <img src={Stack} alt="" />
          </div>
        </div>
        <NearRestaurantCard/>

        {/* Search By Restaurant */}
        <div className="bg-[#FA4F26] py-10">
          <div className="flex justify-center items-center gap-6 py-10">
            <p className="text-[#FFFFFF] text-2xl font-bold">
              Search By Restaurant{" "}
            </p>
            <div className="flex items-center gap-4">
              <img src={Vector} alt="" />
              <div className="flex-grow ">
                <input
                  type="text"
                  placeholder="Enter item or restaurant you are looking for"
                  className="p-2 bg-[#FA4F26] w-[460px] rounded-md placeholder-[#FFFFFF] opacity-50 border border-white outline-none"
                />
              </div>
              <button className="bg-white text-[#FA4F26] px-4 py-2 rounded-md font-bold">
                Search Now
              </button>
            </div>
          </div>
        </div>

        {/* What’s on your mind? */}
        <div className="pt-10 pb-0">
          <div className="flex flex-col items-start py-5 px-28 gap-4">
            <h3 className="text-2xl font-bold my-5">What’s on your mind?</h3>
            <div className="flex justify-center gap-10">
              <div className="flex flex-col items-center gap-2">
                <img src={Sandwich} alt="" width={120} height={120} />
                <span className="text-base font-bold my-6">Sandwich</span>
              </div>
              <div className="flex flex-col items-center gap-2">
                <img src={Thali} alt="" width={120} height={120} />
                <span className="text-base font-bold my-6">
                  North Indian Thali
                </span>
              </div>
              <div className="flex flex-col items-center gap-2">
                <img src={Egg} alt="" width={120} height={120} />
                <span className="text-base font-bold my-6">Egg BreakFast</span>
              </div>
              <div className="flex flex-col items-center gap-2">
                <img src={Rolls} alt="" width={120} height={120} />
                <span className="text-base font-bold my-6">Rolls & Puffs</span>
              </div>
              <div className="flex flex-col items-center gap-2">
                <img src={Salad} alt="" width={120} height={120} />
                <span className="text-base font-bold my-6">Salads</span>
              </div>
              <div className="flex flex-col items-center gap-2">
                <img src={Biryani} alt="" width={120} height={120} />
                <span className="text-base font-bold my-6">Biryani Box</span>
              </div>
              <div className="flex flex-col items-center gap-2">
                <img src={Biryani} alt="" width={120} height={120} />
                <span className="text-base font-bold my-6">Biryani Box</span>
              </div>
            </div>
          </div>
        </div>

        <div className="w-[85%] justify-center items-center m-auto">
          <PlayStore />
        </div>

        <FAQs/>
      </div>
      <Footer />
    </div>
  );
}
