import { BaseModal } from "./BaseModal";
import { Formik, Field, Form, useFormik, FormikProvider } from "formik";
import { useState } from "react";
import { addItemToCart } from "../api/cart/cart";

export const MenuModal = (props: any) => {
  const { handleClose, menuDetails } = props;
  const [qty, setQty] = useState(1);
  const [err, setErr] = useState(null);
  const [selectedReqVariant, setSelectedReqVariant] = useState<any>(null);
  const [selectedOptVariant, setSelectedOptVariant] = useState<any>(null);

  const handleAddToCart = async () => {
    const vars = [
      selectedReqVariant ? selectedReqVariant : null,
      selectedOptVariant ? selectedOptVariant : null,
    ];
    let payload: any = {
      restaurant_menue_id: menuDetails.id,
      quantity: qty,
      menue_varient_id: vars.filter((v) => v),
    };
    //
    if (localStorage.getItem("accessToken")) {
      const res = await addItemToCart(payload);
      if (res?.request?.status !== 200) {
        setErr(res?.response?.data.records);
        setTimeout(() => {
          setErr(null);
        }, 2000);
      } else {
        handleClose();
      }
    } else {
      payload.restaurant_menue = {};
      payload.id = menuDetails.id;
      payload.restaurant_menue["regular_price"] = menuDetails.regular_price;
      payload.restaurant_menue["item_name"] = menuDetails.item_name;
      payload.restaurant_menue["description"] = menuDetails.description;
      payload.restaurant_menue["restaurant_id"] = menuDetails.restaurant_id;
      payload["restaurant_file"] = menuDetails.restaurant_file;
      let data: any = [];
      let prevList: any = localStorage.getItem("u%c%");
      if (prevList) {
        prevList = JSON.parse(prevList);
        data = [...prevList, payload];
        localStorage.setItem("u%c%", JSON.stringify(data));
      } else localStorage.setItem("u%c%", JSON.stringify([payload]));
      handleClose();
    }

    // const res = await addItemToCart(payload)
    // console.log(res)
    // if(res?.request?.status !== 200){
    //     setErr(res?.response?.data.records)
    //     setTimeout(()=>{
    //         setErr(null)
    //     },2000)
    // }
    // else{
    //     handleClose()
    // }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      // alert(JSON.stringify(values, null, 2));
      handleAddToCart();
    },
  });

  return (
    <BaseModal handleClose={handleClose}>
      <div
        className="text-left 2xl:text-[28px] xl:text-[28px] 1xl:text-[26px] lg:text-[24px] md:text-[20px] sm:text-[20px] xs:text-[20px]
                 font-[Gilroy-Semibold] text-primary"
      >
        {menuDetails ? menuDetails.item_name : "N/A"}
      </div>
      <div
        className="text-left 2xl:text-[17px] xl:text-[17px] 1xl:text-[16px] lg:text-[15px] md:text-[14px]
                 font-[Gilroy-Semibold] text-[#8A8A8A]"
      >
        {menuDetails ? menuDetails.description : "N/A"}
      </div>
      <div className="text-left font-[Gilroy-Semibold] text-[16px] text-[#8A8A8A]">
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            {menuDetails.restaurant_menue_variant.map(
              (variant: any, index: number) =>
                variant.variant_type === "Required" ? (
                  <>
                    {index == 0 ? (
                      <>
                        <p className="text-[black] text-[17px] font-[Gilroy-Semibold] pt-5">
                          {/*{variant.menue_type}*/}
                          Required
                        </p>
                        <p className="text-[15px]">
                          Please select any one Option
                        </p>
                      </>
                    ) : null}

                    <div className="flex flex-col">
                      <div className="flex flex-row items-center justify-between">
                        <div className="flex flex-row items-center gap-2 accent-primary">
                          <input
                            id="required"
                            name="required"
                            value={variant.id}
                            type="radio"
                            onChange={() => {
                              setSelectedReqVariant(variant.id);
                            }}
                          />
                          <label htmlFor="required">
                            {variant.variant_name}
                          </label>
                        </div>
                        <div>${variant.variant_price}</div>
                      </div>
                    </div>
                  </>
                ) : null
            )}

            {menuDetails.restaurant_menue_variant.map(
              (variant: any, index: number) =>
                variant.variant_type === "Optional" ? (
                  <>
                    {index == 0 ? (
                      <>
                        <p className="text-[black] font-[Gilroy-Semibold pt-5">
                          {/*{variant.menue_type}*/}
                          Optional Addons
                        </p>
                        <p className="text-[13px]">
                          Please select any one Option
                        </p>
                      </>
                    ) : null}

                    <div className="flex flex-col">
                      <div className="flex flex-row items-center justify-between">
                        <div className="flex flex-row items-center gap-2 accent-primary">
                          <input
                            id="optional"
                            name="optional"
                            value={variant.id}
                            type="radio"
                            onChange={() => {
                              setSelectedOptVariant(variant.id);
                            }}
                          />
                          <label htmlFor="optional">
                            {variant.variant_name}
                          </label>
                        </div>
                        <div>${variant.variant_price}</div>
                      </div>
                    </div>
                  </>
                ) : null
            )}

            <div className="flex flex-row justify-between pt-5">
              <div className="flex flex-row">
                <div
                  className="rounded-md bg-primary bg-opacity-20 hover:bg-opacity-100 border border-primary cursor-pointer
                                        border-1 pl-3 pr-3 text-primary hover:text-white text-[25px]"
                  onClick={() => {
                    if (qty !== 1) setQty(qty - 1);
                  }}
                >
                  -
                </div>
                <div className="text-[black] text-[16px] text-center min-w-[2rem] m-auto">
                  {qty}
                </div>
                <div
                  className="rounded-md bg-primary bg-opacity-20 hover:bg-opacity-100 border border-primary cursor-pointer
                                        border-1 pl-3 pr-3 text-primary hover:text-white text-[25px]"
                  onClick={() => setQty(qty + 1)}
                >
                  +
                </div>
              </div>

              <button
                id="add-to-cart"
                className="rounded-md bg-primary p-2 pl-4 pr-4 text-white text-[14px]"
                type="submit"
              >
                Add to Cart
              </button>
            </div>
          </form>
        </FormikProvider>

        {err && (
          <div
            className="rounded-md bg-primary bg-opacity-20 hover:bg-opacity-100 border border-primary cursor-pointer border-1 mt-2 p-2 text-primary hover:text-white text-[14px]"
          >
            {err}
          </div>
        )}
      </div>
    </BaseModal>
  );
};
