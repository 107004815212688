import {useEffect, useState} from "react";
// import {Box, useMediaQuery} from "@mui/material";
import { Outlet } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { useDispatch } from "react-redux";
import  Navbar  from "../components/Navbar";
import NavbarGuest from "../components/NavbarGuest";
// import { useGetUserByIdQuery } from "../store/api/userApi";
// import { setUser } from "../store/state/userInfo";

const BaseLayout = () => {
    let isLoggedIn = localStorage.getItem("accessToken")

    // const isNonMobile = useMediaQuery("(min-width: 600px)");
    // const [sidebarOpen, setSidebarOpen] = useState(true);
    // const dispatch = useDispatch();
    // let user = useGetUserByIdQuery("63701cc1f03239c72c000180");
    // const [userData, setUserData] = useState({});
    // let storedUserData = useSelector(state => state.userInfo);
    //
    // useEffect(()=>{
    //     if(!userData?.userId){
    //         let data =  user.data;
    //         dispatch(setUser(data));
    //         if(storedUserData){
    //             let img = {img: storedUserData.img}
    //             data = {...data, img}
    //         }
    //         setUserData(data);
    //         console.log("storedUserData 111 :: ", data, storedUserData?.img)
    //     }
    // }, [user])


    // useEffect(()=>{
    //     if(!userData?.userId && user){
    //         let data =  user.data;
    //         dispatch(setUser(data));
    //         if(storedUserData){
    //             let img = {img: storedUserData.img}
    //             data = {...data, img}
    //         }
    //         setUserData(data);
    //         console.log("storedUserData 111 :: ", data, storedUserData?.img)
    //     }
    // }, [user])






    return(
        <div className="max-w-[100%]">
            {   isLoggedIn?
                <Navbar/>
                :
                <NavbarGuest/>
            }
            <Outlet/>
        </div>
    )
}

export default BaseLayout;
