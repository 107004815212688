export const loginURI = 'login';
export const registerURI = 'register';
export const forgotPasswordURI = 'accountemail';
export const changePasswordReqURI = 'change_password';
export const editUserProfileURI = 'editprofile';

// Menu
export const getAllMenuItemsURI = 'product';

// CART
export const getUserCartItemsURI = 'add_cart';
export const addItemToCartURI = 'add_cart';
export const updateCartItemURI ='add_cart/:id';
export const deleteCartItemURI ='add_cart/:id';

// Restaurant
export const getAllRestaurantsURI = 'restaurant_list';
export const getRestaurantByIdURI = `restaurant?business_id=:id`;


// address
export const getUserAddressListURI = 'useraddress'
export const saveUserAddressURI = 'user_address'
export const updateUserAddressURI = 'update_user_address/:id'
export const deleteUserAddressURI = 'delete_user_address/:id'

//payment
export const getUserPaymentMethodsURI = 'user_payment_cart_infomation';
export const addUserPaymentMethodURI = 'user_payment_cart_infomation';
export const updateUserPaymentMethodURI = 'user_payment_cart_infomation/:id';
export const deleteUserPaymentMethodURI = 'user_payment_cart_infomation/:id';

export const orderPaymentURI = 'stripe_pament';


// favourites
export const getUserFavListURI = 'fav_menu';
export const saveUserFavListURI = 'fav_menu';
export const deleteUserFavMenuURI = 'fav_menu/:id';


// MISC
export const cuisineListURI = 'cuisine_list';
export const verifyEmailOtpURI = 'verify_otp';

// Settings
export const getDataSettingsListURI = 'generalSettings'
export const saveDataSettingsURI = 'user_address'
export const updateDataSettingsURI = 'general_settings/:id'
export const deleteDataSettingsURI = 'delete_general_settings/:id'