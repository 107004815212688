import { BaseModal } from "./BaseModal";
import { Formik, Field, Form, useFormik, FormikProvider } from "formik";
import { useEffect, useState } from "react";
import { addItemToCart } from "../api/cart/cart";
import { BsCheckCircleFill } from "react-icons/bs";
import { FaCcVisa } from "react-icons/fa";
import { FiArrowLeft } from "react-icons/fi";
import { RestaurantCard } from "./CartCard/RestaurantCard";
import { CartItem } from "./CartCard/CartItem";
import { AbstractCartCard } from "./CartCard/AbstractCartCard";
import { AddressCard } from "./AddressCard";
import { GoHomeFill } from "react-icons/go";
import { AddNewAddressModal } from "./AddNewAddressModal";
import { AddNewPaymentModal } from "./AddNewPaymentModal";
import { getUserAddressList } from "../api/address/address";
import { PaymentMethodsCard } from "./PaymentMethodsCard";
import { confirmOrderPayment } from "../api/order/order";
import { Link, useNavigate } from "react-router-dom";
import "../index.css";
import { ImSpinner3 } from "react-icons/im";
import Footer from "./Footer";

export const CheckoutModal = (props: any) => {
  const navigate = useNavigate();
  const { handleClose, menuDetails } = props;
  const [qty, setQty] = useState(1);
  const [err, setErr] = useState(null);
  const [payErr, setPayErr] = useState<any>(null);
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const [addNewAddress, setAddNewAddress] = useState(false);
  const [addNewPayment, setAddNewPayment] = useState(false);
  const [addressList, setAddressList] = useState<any>([]);

  useEffect(() => {
    getUserAddressList().then((res) => {
      console.log("addessssssss", res?.data);
      if (res?.request?.status !== 200) {
        setErr(res?.response?.data.message);
        setTimeout(() => {
          setErr(null);
        }, 2000);
      } else {
        setAddressList(res?.data?.records);
        // handleClose()
      }
    });
  }, []);

  useEffect(() => {
    if (addressList.length > 0) {
      sessionStorage.setItem("add", addressList[0]?.id);
    }
  }, [addressList]);

  const handleAddToCart = async () => {
    const payload = {
      restaurant_menue_id: menuDetails.id,
      quantity: qty,
      //    menue_varient_id[]
    };
    const res = await addItemToCart(payload);
    console.log(res);
    if (res?.request?.status !== 200) {
      setErr(res?.response?.data.message);
      setTimeout(() => {
        setErr(null);
      }, 2000);
    } else {
      handleClose();
    }
  };

  const handleOrderPayment = async () => {
    setApiLoading(true);
    const res = await confirmOrderPayment();
    console.log("res ::::::::::::::::::::::::", res);
    if (res?.request?.status !== 200) {
      setPayErr({
        success: false,
        msg: res?.response?.data?.records?.error,
      });
      setTimeout(() => {
        setPayErr(null);
        navigate("/order-failed");
      }, 2000);
    } else {
      setPayErr({
        success: true,
        msg: "Payment Successfully Completed!",
      });
      setTimeout(() => {
        setPayErr(null);
        navigate("/order-success");
      }, 2000);
    }
    setApiLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      alert(JSON.stringify(menuDetails, null, 2));
      handleAddToCart();
    },
  });

  const handleAddNewAddress = () => {
    setAddNewAddress(true);
  };

  return (
    <div className="w-[100%]">
      <div className="w-[85%] mx-auto pt-16 ">

        {/* check out text */}
        
        <div className="text-left flex items-center gap-2 2xl:text-[24px] xl:text-[24px] 1xl:text-[22px] lg:text-[14px] md:text-[10px] font-[Gilroy-Semibold] text-[black] pb-10">
          <FiArrowLeft
            className="bg-primary cursor-pointer bg-opacity-20 text-primary"
            style={{
              borderRadius: "0.7rem",
              padding: "2px",
              fontSize: "32px",
            }}
            onClick={() => {
              window.history.back();
            }}
          />
          Checkout
        </div>

        {/*  checkout stuff   */}
        <div
          className="flex 4xl:flex-row 3xl:flex-row 2xl:flex-row xl:flex-row 1xl:flex-row lg:flex-row md2:flex-col md:flex-col sm:flex-col xs:flex-col gap-10 m-auto"
        >
          {/*  address & payments card   */}
          <div className="basis-[60%]">
            <div className="flex flex-col gap-5">
              {/* Delivey Address Selected Card   */}
              {/* drop-shadow(0 -19px 13px rgb(0 0 0 / 0.03)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.08)) !important */}
              <div className="flex flex-col py-4 px-2 items-start justify-start w-[100%] rounded-xl app-shadow bg-[white] max-h-[90px] ">
                <div className="flex flex-row w-[100%]">
                  <div className="basis-[7%] rounded-full flex justify-center text-[green]">
                    <BsCheckCircleFill size={24} />
                  </div>
                  <div className="basis-[73%] pl-2">
                    <div className="text-left 2xl:text-[15px] xl:text-[15px] 1xl:text-[12px] lg:text-[10px] md:text-[16px] font-[Gilroy-Semibold] text-sec-grey">
                      Delivery at Work
                    </div>
                  </div>
                  <div className="basis-[15%] underline text-primary text-right 2xl:text-[14px] xl:text-[14px] 1xl:text-[12px] lg:text-[12px] md:text-[10px]">
                    <Link to="#">Change</Link>
                  </div>
                  <div className="basis-[5%]"></div>
                </div>
                <div className="flex flex-row w-[100%]">
                  <div className="basis-[7%] flex justify-center text-[green]">
                    {/*<BsCheckCircleFill size={20} />*/}
                  </div>
                  <div className="basis-[53%] pl-2">
                    <div className="text-left text-primary flex gap-2">
                      <div
                        className="text-left 2xl:text-[12px] xl:text-[12px] 1xl:text-[12px] lg:text-[12px] md:text-[12px]*/} {/*font-[Gilroy] text-sec-grey"
                      >
                        {addressList[0] ? `${addressList[0].address} ${addressList[0].street ? addressList[0].street : ""}, ${addressList[0].city}` : "No Address Found"}
                        <br />
                        {addressList[0]?.country ? addressList[0].country : ""}
                      </div>
                    </div>
                  </div>
                  <div className="basis-[30%]"></div>
                  <div className="basis-[10%]"></div>
                </div>
              </div>

              {/* addresses Card / if present */}
              {addressList.map((address: any) => (
                <AddressCard
                  key={address.id}
                  id={address.id}
                  addressType={address.address_type}
                  address={address.address}
                  city={address.city}
                  country={address.country}
                  latitude={address.latitude}
                  longitude={address.longitude}
                />
              ))}

              {/* Add new Address Button  */}
              <div className="w-[100%]">
                <button
                  className="m-auto pr-20 pl-20 p-2 rounded-full bg-primary text-white font-[Gilroy-Semibold]"
                  onClick={handleAddNewAddress}
                >
                  Add new Address
                </button>
              </div>

              {/* Payment method select Card   */}
              <div className="flex flex-col gap-4 p-2 items-start justify-start w-[100%] rounded-xl app-shadow  bg-[white] max-h-[90px] ">
                <div className="flex flex-row w-[100%]">
                  <div className="basis-[7%] flex justify-center text-[green]">
                    {/*<BsCheckCircleFill size={20} />*/}
                  </div>
                  <div className="basis-[73%] pl-2">
                    <div
                      className="text-left 2xl:text-[14px] xl:text-[14px] 1xl:text-[12px] lg:text-[10px] md:text-[16px]
                        font-[Gilroy-Semibold] text-sec-grey"
                    >
                      Payment Method
                    </div>
                  </div>
                  <div className="basis-[15%] underline text-primary text-right 2xl:text-[12px] xl:text-[12px] 1xl:text-[10px] lg:text-[10px] md:text-[8px]">
                    <div onClick={() => setAddNewPayment(true)}>Change</div>
                  </div>
                  <div className="basis-[5%]"></div>
                </div>
                <div className="flex flex-row w-[100%]">
                  <div className="basis-[7%] flex justify-center text-[green]">
                    {/*<BsCheckCircleFill size={20} />*/}
                  </div>
                  <div className="basis-[53%] pl-2">
                    <div className="text-left text-primary flex gap-2">
                      <FaCcVisa size={20} />
                      <span
                        className="text-left 2xl:text-[14px] xl:text-[14px] 1xl:text-[12px] lg:text-[10px] md:text-[8px]
                        font-[Gilroy-Semibold] text-sec-grey"
                      >
                        Stripe
                      </span>
                    </div>
                  </div>
                  <div className="basis-[35%] font-[Gilroy-Semibold] text-primary text-right pl-5 2xl:text-[12px] xl:text-[12px] 1xl:text-[10px] lg:text-[10px] md:text-[8px]">
                    <Link to="#">************1234</Link>
                  </div>
                  {/* <div className="basis-[5%]">
                                    </div> */}
                </div>
              </div>

              <PaymentMethodsCard />
              {/* Add new Address Button  */}
              <div className="w-[100%]">
                <button
                  className="m-auto pr-20 pl-20 p-2 rounded-full bg-primary text-white font-[Gilroy-Semibold]"
                  onClick={() => setAddNewPayment(true)}
                >
                  Add new Payment
                </button>
              </div>
            </div>
          </div>

          {/* order summary card  */}
          <div className="basis-[35%]">
            <div>
              <div className="bg-primary max-w-[100%] min-h-[80px] pt-2 pb-5 flex flex-col justify-center rounded-2xl">
                <div className="flex flex-row w-[100%] pt-3 pb-3 justify-between items-start gap-1 accent-primary">
                  <div className="w-[100%] pl-5 font-[Gilroy] text-[14px] text-white text-left">
                    Order Summary
                  </div>
                </div>

                <div className="flex flex-row w-[100%] p-1 justify-between items-start gap-1 accent-primary">
                  <div className="font-[Gilroy] pl-14 text-[14px] text-white text-left">
                    Subtotal
                  </div>
                  <div className="font-[Gilroy-Semibold] pr-14 text-[14px] text-white text-right">
                    ${sessionStorage.getItem("subTotal")}
                  </div>
                </div>

                <div className="flex flex-row w-[100%] p-1 justify-between items-start gap-1 accent-primary">
                  <div className="font-[Gilroy] pl-14 text-[14px] text-white text-left">
                    Tax & Fee
                  </div>
                  <div className="font-[Gilroy-Semibold] pr-14 text-[14px] text-white text-right">
                    ${sessionStorage.getItem("tax")}
                  </div>
                </div>

                <div className="flex flex-row w-[100%] p-1 pt-2 justify-between items-start gap-1 accent-primary">
                  <div className="font-[Gilroy-Semibold] pl-14 text-[14px] text-white text-left">
                    Total
                  </div>
                  <div className="font-[Gilroy-Semibold] pr-14 text-[14px] text-white text-right">
                    ${sessionStorage.getItem("total")}
                  </div>
                </div>

                <div className="pt-5">
                  {apiLoading ? (
                    <div className="grid grid-cols-1 mt-5 justify-items-center">
                      <ImSpinner3 size={20} color="white" />
                    </div>
                  ) : (
                    <button
                      className="font-[Gilroy-Semibold] text-[16px] text-primary rounded-full bg-white p-2 w-[65%] "
                      onClick={handleOrderPayment}
                    >
                      Send Order
                    </button>
                  )}
                </div>
              </div>

              {payErr ? (
                <div
                  className={`font-[Gilroy] text-[16px] text-left p-2 w-[100%] ${
                    payErr.success
                      ? "bg-[green] text-white"
                      : "text-primary bg-primary"
                  } bg-opacity-20`}
                >
                  {payErr?.msg}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <Footer />

      {addNewAddress && (
        <AddNewAddressModal handleClose={() => setAddNewAddress(false)} />
      )}
      {addNewPayment && (
        <AddNewPaymentModal handleClose={() => setAddNewPayment(false)} />
      )}
    </div>
  );
};
