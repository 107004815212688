import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Burger from "../assets/burger.png"
import Fries from "../assets/fries.svg"
import Sandwich from "../assets/sandwixh.png"
import Pizza from "../assets/pizza.png"
import Donuts from "../assets/donuts.png"


const cuisines = [
  { name: 'Burger', image: Burger },
  { name: 'Fries', image: Fries },
  { name: 'Sandwich', image: Sandwich },
  { name: 'Pizza', image: Pizza },
  { name: 'Donuts', image: Donuts },
  { name: 'Burger', image: Burger },
  { name: 'Fries', image: Fries },
  { name: 'Sandwich', image: Sandwich },
  { name: 'Pizza', image: Pizza },
  { name: 'Donuts', image: Donuts },
  { name: 'Burger', image: Burger },
  { name: 'Fries', image: Fries },
  { name: 'Sandwich', image: Sandwich },
  { name: 'Pizza', image: Pizza },
  { name: 'Donuts', image: Donuts },
  { name: 'Burger', image: Burger },
  { name: 'Fries', image: Fries },
  { name: 'Sandwich', image: Sandwich },
  { name: 'Pizza', image: Pizza },
  { name: 'Donuts', image: Donuts },
  
];

function CuisineMenu() {
    return (
      <div className="my-swiper-container">
        <Swiper
          slidesPerView={12}
          spaceBetween={32}
          loop={true} 
          
        >
          {cuisines.map((cuisine, index) => (
            <SwiperSlide key={index}>
              <div className='flex flex-col gap-3 items-center justify-between rounded-lg py-4 border-[1.5px] border-[#FA4F26] h-[122px] w-[92px] text-[#FA4F26] text-base font-semibold hover:bg-[#FA4F26] transition-all duration-300 hover:text-white cursor-pointer'>
              <p>{cuisine.name}</p>
              <img src={cuisine.image} alt={cuisine.name} className="hover:opacity-75" />
               
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  }
  

export default CuisineMenu;
