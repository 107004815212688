import restaurant from "../assets/restaurant.png"
import {AiFillStar, AiOutlineStar, AiFillHeart} from "react-icons/ai";
import {HiOutlineClock} from "react-icons/hi";
import {FaCcVisa} from "react-icons/fa";
import {IoMdClose} from "react-icons/io";
import {useEffect, useState} from "react";
import {deleteUserAddress, getUserAddressList} from "../api/address/address";
import {deleteUserPaymentMethod, getUserPaymentMethods} from "../api/payment/payment";
import {Session} from "inspector";
import {useNavigate} from "react-router-dom";

export function PaymentMethodsCard(props:any) {
    const navigate = useNavigate()
    // const {type, name, price, rating, totalRatings, cuisines, startingPrice
    //     , deliveryTime, handleAddToCart} = props
    const [err, setErr] = useState<any>(null)
    const [paymentList, setPaymentList] = useState<any>([])
    const [selectedOption, setSelectedOption] = useState('cash');

    const handleOptionChange = (event:any) => {
        console.log("event.target.value ::", event)
        if(event.target.value == "card"){
            setSelectedOption(paymentList[0]?.id)
        }
        setSelectedOption(event.target.value);
        sessionStorage.setItem("pay", event.target.value)
    };

    async function handleDeletePayment (){
        // const res = await deleteUserPayment({id})
        // console.log("res delete ", res)
        // navigate(0)
    }

    useEffect(()=>{
        getUserPaymentMethods().then(
            (res)=>{
                console.log("payments", res?.data)
                if(res?.request?.status !== 200){
                    setErr(res?.response?.data.message)
                    setTimeout(()=>{
                        setErr(null)
                    },2000)
                    // setPaymentList([{id:1},{id:2})
                }
                else{
                    setPaymentList(res?.data?.records)
                    // handleClose()
                }
            }
        )
    }, [])

    return (
        <div className="w-[100%]">
            <div className="flex flex-col p-4 gap-3 items-start justify-start w-[100%] rounded-xl app-shadow bg-[white] mb-5 min-h-[200px]">
                <div className={`flex flex-row items-center gap-1 font-[Gilroy] text-[13px] accent-primary`}>
                    <input name="payment" type="radio" checked={selectedOption != "cash" && selectedOption != "cod" ? true : false}
                           onChange={()=>handleOptionChange({
                               target: {value: "card"}
                           })}
                    />
                    <label htmlFor="payment"
                           className={`${selectedOption != "card"? "" : "text-primary font-[Gilroy-Semibold]"}`}
                    >Set as Primary</label>
                </div>
                <div className="flex flex-row justify-between items-center w-[100%] bg-primary bg-opacity-20 rounded-xl max-h-[55px] p-2 pr-4 pl-4">
                    <div className={`flex flex-row font-[Gilroy] gap-2 text-[12px] items-center w-[80%] ${selectedOption != "card"? "" : "text-primary font-[Gilroy-Semibold]"} `}>
                        <FaCcVisa size={20}/>
                        <div className={`${selectedOption != "cash" && selectedOption != "cod"? "text-primary font-[Gilroy-Semibold]" : ""}`}>
                            Credit Card / Debit Card
                        </div>
                    </div>
                    <div>
                        {/*<button className="rounded-full bg-primary bg-opacity-[20%] border-0 border-none p-1"*/}
                        {/*        onClick={async ()=>{*/}
                        {/*            const res = await deleteUserPaymentMethod({id: p.id})*/}
                        {/*            console.log("res delete ", res)*/}
                        {/*            navigate(0)*/}
                        {/*        }}>*/}
                        {/*    <IoMdClose className="text-primary" size={18} />*/}
                        {/*</button>*/}
                    </div>
                </div>
                {
                    paymentList.map((p:any)=>(
                        <>
                            <div className="accent-primary pl-10 flex flex-row gap-2 items-center">
                                <input name="card" type="radio" checked={selectedOption ==  p.id ? true : false}
                                       onChange={()=>handleOptionChange({
                                           target: {value: p.id}
                                       })}
                                />
                                <label>
                                    {p.card_number}
                                </label>
                            </div>
                            {/*<div className={`flex flex-row items-center gap-1 font-[Gilroy] text-[13px] accent-primary`}>*/}
                            {/*    <input name="payment" value={p.id} type="radio"*/}
                            {/*           onChange={handleOptionChange}*/}
                            {/*    />*/}
                            {/*    <label htmlFor="payment"*/}
                            {/*    className={`${selectedOption == p.id? "text-primary font-[Gilroy-Semibold]" : ""}`}*/}
                            {/*    >Set as Primary</label>*/}
                            {/*</div>*/}

                            {/*<div className="flex flex-row justify-between items-center w-[100%] bg-primary bg-opacity-20 rounded-xl max-h-[55px] p-2 pr-4 pl-4">*/}
                            {/*    <div className={`flex flex-row font-[Gilroy] gap-2 text-[12px] items-center w-[80%] ${selectedOption == p.id? "text-primary font-[Gilroy-Semibold]" : ""} `}>*/}
                            {/*        <FaCcVisa size={20}/>*/}
                            {/*        <div className={`${selectedOption == p.id? "text-primary font-[Gilroy-Semibold]" : ""}`}>*/}
                            {/*            Credit Card / Debit Card*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*    <div>*/}
                            {/*        <button className="rounded-full bg-primary bg-opacity-[20%] border-0 border-none p-1"*/}
                            {/*                onClick={async ()=>{*/}
                            {/*                    const res = await deleteUserPaymentMethod({id: p.id})*/}
                            {/*                    console.log("res delete ", res)*/}
                            {/*                    navigate(0)*/}
                            {/*                }}>*/}
                            {/*            <IoMdClose className="text-primary" size={18} />*/}
                            {/*        </button>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </>
                    ))
                }

                    <>
                        <div className="flex flex-row items-center gap-1 font-[Gilroy] text-[13px] accent-primary">
                            <input name="payment" value="cash" type="radio" checked={selectedOption == "cash"? true : false}
                                   onChange={handleOptionChange}
                            />
                            <label htmlFor="payment"
                            className={`${selectedOption == "cash"? "text-primary font-[Gilroy-Semibold]" : ""}`}
                            >Set as Primary</label>
                        </div>
                        <div className={`flex flex-row justify-between items-center w-[100%] bg-primary bg-opacity-20 rounded-xl max-h-[55px] p-2 pr-4 pl-4 ${selectedOption == "cash"? "text-primary font-[Gilroy-Semibold]" : ""}`}>
                            <div className={`flex flex-row font-[Gilroy] gap-2 text-[12px] items-center w-[80%]`}>
                                <div className=" flex flex-row justify-center bg-[black] w-[20px] p-1 rounded-sm">
                                    <svg width="15" height="6" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.9365 7.43752C14.5512 7.99189 14.1164 8.4173 13.5552 8.68615C12.7845 9.05702 12.0043 9.41955 11.2163 9.75641C10.3731 10.117 9.51128 10.0253 8.64176 9.82378C7.06518 9.45741 5.48281 9.11349 3.90045 8.77085C3.68018 8.72273 3.59541 8.6515 3.59798 8.4019C3.61404 6.90239 3.60826 5.40223 3.60312 3.90271C3.60312 3.6807 3.56138 3.45869 3.5389 3.20909C4.50733 2.79138 5.44428 2.26588 6.58995 2.29924C8.25965 2.34801 9.92935 2.30758 11.5991 2.314C12.4069 2.31721 12.9374 3.05638 12.6574 3.77951C12.5026 4.1799 12.195 4.40126 11.7776 4.47954C10.7289 4.67204 9.68275 4.88185 8.63084 5.06151C7.91929 5.18343 7.45049 5.56199 7.22637 6.24599C7.18655 6.3679 7.13967 6.48724 7.09793 6.60723C6.90527 7.14364 7.10435 7.43687 7.66884 7.43752H14.9365Z" fill="white"/>
                                        <path d="M8.26789 6.31784C8.42266 6.24149 8.56972 6.12535 8.73348 6.09391C9.79502 5.88409 10.8598 5.68968 11.9245 5.4959C12.9405 5.31047 13.6328 4.58926 13.7406 3.59215C13.7878 3.14181 13.6859 2.68845 13.4505 2.3015C13.2152 1.91455 12.8594 1.61534 12.4376 1.4497C12.1533 1.3423 11.8522 1.28604 11.5482 1.28352C9.83098 1.26897 8.11355 1.26897 6.3959 1.28352C5.98939 1.28352 5.58417 1.35474 5.17573 1.3926C5.00041 0.604019 5.46793 0.00408316 6.28351 0.00344133C9.80658 0.00344133 13.3299 0.00344133 16.8534 0.00344133C17.5899 0.00344133 17.9977 0.413451 17.999 1.15198C17.999 2.52339 17.999 3.89436 17.999 5.26491C17.999 6.00152 17.5842 6.41602 16.8527 6.41602H8.30835L8.26789 6.31784Z" fill="white"/>
                                        <path d="M2.58398 6.15403C2.58398 6.88358 2.58398 7.61312 2.58398 8.34267C2.58398 9.07222 2.16784 9.48993 1.4351 9.49121C1.31437 9.49121 1.19428 9.49121 1.07419 9.49121C0.469888 9.48287 0.0203547 9.07992 0.0139327 8.47613C-0.00147986 6.92892 -0.00147986 5.3817 0.0139327 3.83449C0.0190701 3.25124 0.44099 2.85149 1.02474 2.8162C1.17694 2.80722 1.32978 2.81107 1.48198 2.8162C2.15243 2.82711 2.57885 3.2506 2.58334 3.91983C2.58398 4.66606 2.58398 5.41165 2.58398 6.15403Z" fill="white"/>
                                    </svg>

                                </div>
                                <div>
                                    Cash on Delivery
                                </div>
                            </div>
                            <div>
                                <button className="rounded-full bg-primary bg-opacity-[20%] border-0 border-none p-1"
                                        onClick={()=>{}}>
                                    <IoMdClose className="text-primary" size={18} />
                                </button>
                            </div>
                        </div>
                    </>


                    <>
                        <div className="flex flex-row items-center gap-1 font-[Gilroy] text-[13px] accent-primary">
                            <input name="cod" value="cod" type="radio" checked={selectedOption == "cod"? true : false}
                                   onChange={handleOptionChange}
                            />
                            <label htmlFor="cod"
                            className={`${selectedOption == "cod"? "text-primary font-[Gilroy-Semibold]" : ""}`}
                            >Set as Primary</label>
                        </div>
                        <div className={`flex flex-row justify-between items-center w-[100%] bg-primary bg-opacity-20 rounded-xl max-h-[55px] p-2 pr-4 pl-4 ${selectedOption == "cod"? "text-primary font-[Gilroy-Semibold]" : ""}`}>
                            <div className={`flex flex-row font-[Gilroy] gap-2 text-[12px] items-center w-[80%]`}>
                                <div className=" flex flex-row justify-center bg-[black] w-[20px] p-1 rounded-sm">
                                    <svg width="15" height="6" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.9365 7.43752C14.5512 7.99189 14.1164 8.4173 13.5552 8.68615C12.7845 9.05702 12.0043 9.41955 11.2163 9.75641C10.3731 10.117 9.51128 10.0253 8.64176 9.82378C7.06518 9.45741 5.48281 9.11349 3.90045 8.77085C3.68018 8.72273 3.59541 8.6515 3.59798 8.4019C3.61404 6.90239 3.60826 5.40223 3.60312 3.90271C3.60312 3.6807 3.56138 3.45869 3.5389 3.20909C4.50733 2.79138 5.44428 2.26588 6.58995 2.29924C8.25965 2.34801 9.92935 2.30758 11.5991 2.314C12.4069 2.31721 12.9374 3.05638 12.6574 3.77951C12.5026 4.1799 12.195 4.40126 11.7776 4.47954C10.7289 4.67204 9.68275 4.88185 8.63084 5.06151C7.91929 5.18343 7.45049 5.56199 7.22637 6.24599C7.18655 6.3679 7.13967 6.48724 7.09793 6.60723C6.90527 7.14364 7.10435 7.43687 7.66884 7.43752H14.9365Z" fill="white"/>
                                        <path d="M8.26789 6.31784C8.42266 6.24149 8.56972 6.12535 8.73348 6.09391C9.79502 5.88409 10.8598 5.68968 11.9245 5.4959C12.9405 5.31047 13.6328 4.58926 13.7406 3.59215C13.7878 3.14181 13.6859 2.68845 13.4505 2.3015C13.2152 1.91455 12.8594 1.61534 12.4376 1.4497C12.1533 1.3423 11.8522 1.28604 11.5482 1.28352C9.83098 1.26897 8.11355 1.26897 6.3959 1.28352C5.98939 1.28352 5.58417 1.35474 5.17573 1.3926C5.00041 0.604019 5.46793 0.00408316 6.28351 0.00344133C9.80658 0.00344133 13.3299 0.00344133 16.8534 0.00344133C17.5899 0.00344133 17.9977 0.413451 17.999 1.15198C17.999 2.52339 17.999 3.89436 17.999 5.26491C17.999 6.00152 17.5842 6.41602 16.8527 6.41602H8.30835L8.26789 6.31784Z" fill="white"/>
                                        <path d="M2.58398 6.15403C2.58398 6.88358 2.58398 7.61312 2.58398 8.34267C2.58398 9.07222 2.16784 9.48993 1.4351 9.49121C1.31437 9.49121 1.19428 9.49121 1.07419 9.49121C0.469888 9.48287 0.0203547 9.07992 0.0139327 8.47613C-0.00147986 6.92892 -0.00147986 5.3817 0.0139327 3.83449C0.0190701 3.25124 0.44099 2.85149 1.02474 2.8162C1.17694 2.80722 1.32978 2.81107 1.48198 2.8162C2.15243 2.82711 2.57885 3.2506 2.58334 3.91983C2.58398 4.66606 2.58398 5.41165 2.58398 6.15403Z" fill="white"/>
                                    </svg>

                                </div>
                                <div>
                                    Self Pick-up
                                </div>
                            </div>
                            <div>
                                <button className="rounded-full bg-primary bg-opacity-[20%] border-0 border-none p-1"
                                        onClick={()=>{}}>
                                    <IoMdClose className="text-primary" size={18} />
                                </button>
                            </div>
                        </div>
                    </>

            </div>
        </div>
    );
}

