import {
    deleteRequest,
    getRequest, patchRequest, postFormDataRequest,
    postRequest
} from "../methodCalls";
import {getUserPaymentMethodsURI, addUserPaymentMethodURI, updateUserPaymentMethodURI, deleteUserPaymentMethodURI} from "../endpoints";



export const getUserPaymentMethods = (params) => {
    return getRequest(`${getUserPaymentMethodsURI}`);
}

export const addUserPaymentMethod = (payload) => {
    return postFormDataRequest(addUserPaymentMethodURI, payload);
}

export const updateUserPaymentMethod = (payload) => {
    const id = payload.menu_id;
    const reqURI = updateUserPaymentMethodURI.replace(':id', id);
    return postFormDataRequest(reqURI, payload);
}

export const deleteUserPaymentMethod = (payload) => {
    const id = payload.id;
    const reqURI = deleteUserPaymentMethodURI.replace(':id', id);
    return deleteRequest(reqURI, payload);
}
