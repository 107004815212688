// export const IMG_STORAGE_BASE_URL="https://eat-today.com";
// export const API_BASE_URL="https://eat-today.com/api";

export const IMG_STORAGE_BASE_URL="https://eat-today.com/wooeats/public/";
export const API_BASE_URL="https://eat-today.com/wooeats";

const a ={
    "id": 1,
    "restaurant_menue_id": 1,
    "variant_name": "chips",
    "variant_price": "1",
    "variant_image": null,
    "variant_description": null,
    "menue_type": "Simple",
    "variant_type": "Required",
    "deleted_at": null,
    "created_at": "2023-09-05T12:56:36.000000Z",
    "updated_at": "2023-09-05T12:56:36.000000Z"
}


let b ={
    "id": 3,
    "user_id": 9,
    "cuisine_id": 1,
    "business_name": "food delivery busi",
    "restaurant_address": "Rawalpindi",
    "business_image": null,
    "business_description": null,
    "starting_price": null,
    "ordr_delivery_time": null,
    "latitude": null,
    "longitude": null,
    "business_type": "Restaurant",
    "deleted_at": null,
    "created_at": "2023-09-05T09:07:42.000000Z",
    "updated_at": "2023-09-05T09:07:42.000000Z",
    "restaurant_menue": [
    {
        "id": 1,
        "restaurant_id": 3,
        "item_name": "Burger",
        "description": "dELICIOUS",
        "regular_price": 10,
        "sale_price": 9,
        "stock": "10",
        "sku": "BUR_23830642",
        "category": "",
        "category_type": "",
        "deleted_at": null,
        "created_at": "2023-09-05T12:56:36.000000Z",
        "updated_at": "2023-09-05T12:56:36.000000Z",
        "restaurant_menue_variant": [
            {
                "id": 1,
                "restaurant_menue_id": 1,
                "variant_name": "chips",
                "variant_price": "1",
                "variant_image": null,
                "variant_description": null,
                "menue_type": "Simple",
                "variant_type": "Required",
                "deleted_at": null,
                "created_at": "2023-09-05T12:56:36.000000Z",
                "updated_at": "2023-09-05T12:56:36.000000Z"
            },
            {
                "id": 2,
                "restaurant_menue_id": 1,
                "variant_name": "extra topping",
                "variant_price": "3",
                "variant_image": null,
                "variant_description": null,
                "menue_type": "Simple",
                "variant_type": "Required",
                "deleted_at": null,
                "created_at": "2023-09-05T12:56:36.000000Z",
                "updated_at": "2023-09-05T12:56:36.000000Z"
            }
        ],
        "restaurant_file": [
            {
                "id": 1,
                "restaurnat_menu_id": 1,
                "restaurant_file": "storage/restaurant_file/1693918596_7568871.png",
                "created_at": "2023-09-05T12:56:36.000000Z",
                "updated_at": "2023-09-05T12:56:36.000000Z"
            }
        ]
    }
],
    "cuisine": {
    "id": 1,
        "cuisine_name": "Fast Food",
        "cuisine_image": "storage/cuisine_image/1691626085_cuisine_imageone.png",
        "created_at": "2023-08-31T18:34:32.000000Z",
        "updated_at": "2023-08-31T18:34:32.000000Z"
}
}
