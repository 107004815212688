import RestaurantImg from "../../assets/rest-cart-img.png";
import { AiFillStar, AiOutlineMinus } from "react-icons/ai";
import { AiOutlinePlus } from "react-icons/ai";
import { useEffect, useState } from "react";
import { BiSolidMap } from "react-icons/bi";
import { getRestaurantById } from "../../api/restaurant/restaurant";
import { ImSpinner3 } from "react-icons/im";
import { IMG_STORAGE_BASE_URL } from "../../config";

export const RestaurantCard = (props: any) => {
  const { itemName, itemSze, itemQty, itemPrice, handleClose, restId } = props;
  const [count, setCount] = useState(itemQty ? parseInt(itemQty) : 1);
  const [restData, setRestData] = useState<any>(null);
  const [apiLoading, setApiLoading] = useState<boolean>(false);

  // const getData = async () => {
  //     // setLoading(true);
  //     const res =
  //
  //     if(res.status === 200){
  //         const menuList = res.data.records.data[0].restaurant_menue;
  //         // setSelectedRestaurant(res.data.records.data[0]);
  //         // const tableData = menuList.map((menuItem)=>{
  //         //     return menuTableDataMapper(menuItem)
  //         // })
  //         // setData(tableData);
  //     }
  //     else{
  //         let err = res.response.data.error ? JSON.stringify(res.response.data.error) : "Error getting Restaurants";
  //     }
  //     // setLoading(false);
  // }

  useEffect(() => {
    setApiLoading(true);
    getRestaurantById({ id: restId }).then((res) => {
      if (res?.status && res.status == 200) {
        setApiLoading(false);
        setRestData(res.data.records.data[0]);
      } else setRestData(null);
    });
  }, []);

  return (
    <>
      {apiLoading ? (
        <div className="grid grid-cols-1 mt-5 justify-items-center">
          <ImSpinner3 size={20} color="light" />
        </div>
      ) : (
        <div
          className="bg-[white] max-w-[100%] min-h-[83px] max-h-[130px]
            flex flex-row justify-center rounded-2xl drop-shadow-md border border-1 border-primary"
        >
          {/*<div className="flex flex-row grow-0 w-[100%] p-5 pt-2 pb-2 justify-start items-start gap-1 accent-primary">*/}
          <div className="flex flex-row grow-0 w-[100%] justify-start items-start gap-2 accent-primary ">
            <div className="basis-[37%] grow-0 h-[100%] w-[100%]">
              <img
                className="object-fill"
                width="100%"
                height="100%"
                // src={restData?.business_image? IMG_STORAGE_BASE_URL + restData.business_image : RestaurantImg}
                src={restData?.business_image ? RestaurantImg : RestaurantImg}
              />
            </div>

            <div className="basis-[63%] m-auto mt-2 mr-2 max-w-[70%]">
              <div className="flex flex-col gap-1 w-[100%] items-left">
                <div className="text-sec-grey font-[Gilroy-Semibold] text-[16px] leading-5 text-left max-w-[100%] grow-0 truncate">
                  {restData ? restData.business_name : "Name of Restaurant"}
                </div>

                <div className="text-sec-grey font-[Gilroy] text-[11px] leading-4 text-left max-w-[100%] grow-0 truncate">
                  {restData
                    ? restData.cuisine.cuisine_name
                    : "Fries - Pizza - Sandwich"}
                </div>

                <div className="flex flex-row w-[100%] items-center text-sec-grey font-[Gilroy] text-[11px] leading-4 text-left">
                  <span className="text-primary font-[Gilroy-Semibold]">
                    <AiFillStar size={15} />
                  </span>
                  &nbsp;
                  <span className="text-primary font-[Gilroy-Semibold]">
                    4.8
                  </span>
                  &nbsp; (250 Ratings)
                </div>

                <div className="flex flex-row w-[100%] items-center text-sec-grey font-[Gilroy] text-[11px] leading-4 text-left truncate">
                  <span className="basis-[8%]">
                    <BiSolidMap />
                  </span>
                  {/*&nbsp;*/}
                  <span className="basis-[80%]">
                    {restData
                      ? restData.restaurant_address
                      : "Robert Robertson,1234 NW Bobcat Lane"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
