import restBanner from "../assets/rest-home-banner.png";
import { RestaurantInfoCard } from "../components/RestaurantInfoCard";
import { FiArrowLeft } from "react-icons/fi";
import { MenuCard } from "../components/MenuCard";
import { BaseModal } from "../components/BaseModal";
import { MenuModal } from "../components/MenuModal";
import { AiOutlineSearch } from "react-icons/ai";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserFavList } from "../api/favourite/favourite";
import { ImSpinner3 } from "react-icons/im";
import Footer from "../components/Footer";

export const Favourites = () => {
  const navigate = useNavigate();
  const [favList, setFavList] = useState([]);
  const [loading, setLoading] = useState(true);

  const getFavData = async () => {
    const res = await getUserFavList();
    setLoading(true);
    console.log("asfascasadascdsc :: ", res);
    if (res.status && res.status === 200) {
      setFavList(res.data.records);
    }
    setLoading(false);
  };

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    const user = localStorage.getItem("user");
    if (!token && !user) {
      navigate("/login");
    } else {
      getFavData();
    }
  }, []);

  return (
    <div className="rest-home bg-[white]  min-h-[89vh] p-0 pl-0 pr-0">
      <div className="w-[85%] m-auto pt-[1rem]">
        <div className="flex items-center gap-2">
          <FiArrowLeft
            className="bg-primary cursor-pointer bg-opacity-20 text-primary"
            style={{
              borderRadius: "0.7rem",
              padding: "2px",
              fontSize: "32px",
            }}
            onClick={() => {
              window.history.back();
            }}
          />
          <div className="text-[28px] font-[Gilroy-Semibold] text-left">
            Favourites
          </div>
        </div>

        {/* search  xl:w-[10%] 2xl:w-[10%]  */}
        <div
          className="border border-1 border-primary  rounded-md flex flex-start 4xl:w-[40%] 3xl:w-[40%] 2xl:w-[40%] xl:w-[40%] 1xl:w-[40%] lg:w-[50%] md2:w-[60%] md:w-[70%] sm:w-[100%] xs:w-[100%] mt-10 items-center text-[14px] font-[Gilroy]">
          <input
            className="p-3 w-[90%] rounded-md outline-none"
            type="text"
            placeholder="Search your food"
          />
          <div className="rounded-md bg-primary min-[1280px]:w-[12%] h-[100%] p-3 cursor-pointer flex flex-row justify-center">
            <AiOutlineSearch
              size={24}
              color={"white"}
              onClick={() => {
                navigate("/restaurants");
              }}
            />
          </div>
        </div>

        {/* Menu / Pizza  etc  */}
        <div className="m-auto">
          <div className="grid 3xl:grid-cols-5 2xl:grid-cols-5 xl:grid-cols-5 1xl:grid-cols-5 lg:grid-cols-4 md2:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-3 mt-3">
            {!loading ? (
              favList.map((f: any) => (
                <MenuCard
                  id={f.id}
                  name={f.restaurant_menue.item_name}
                  price={f.restaurant_menue.regular_price}
                  isFav={true}
                  updateRequest={getFavData}
                />
              ))
            ) : (
              <div className="grid grid-cols-1 mt-5 justify-items-center">
                <p className="text-2xl">
                  You don't have any Favourite Food added yet
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
