import React, {useEffect, useState} from 'react';
import { BrowserRouter, Navigate, Routes, Route  } from "react-router-dom";
import './App.css';
import BaseLayout from "./layout/BaseLayout";
import {Home} from "./pages/Home";
import {RestaurantHome} from "./pages/RestaurantHome";
import {Favourites} from "./pages/Favourites";
import {Checkout} from "./pages/Checkout";
import {GuestHome} from "./pages/GuestHome";
import {LoginScreen} from "./pages/LoginScreen";
import {SignupForm} from "./components/SignupForm";
import {SignupScreen} from "./pages/SignupScreen";
import AuthRoute from "./AuthRoute";
import {OrderSuccess} from "./pages/OrderSuccess";
import {OrderFailure} from "./pages/OrderFailure";
import {TrackOrder} from "./pages/TrackOrder";
import {EditProfile} from "./pages/EditProfile";
import PrivacyPolicy from './pages/PrivacyPolicy';

const auth = localStorage.getItem("accessToken");

function App() {

    // const [loggedIn, setLoggedIn] = useState(false)
    //
    // useEffect(()=>{
    //     if(localStorage.getItem("accessToken")){
    //         setLoggedIn(true)
    //     }
    //     else
    //         setLoggedIn(false)
    // },[])


  return (
    <div className="App">
      {/*<h1 className="text-3xl font-bold underline text-red-600">*/}
      {/*  Simple React Typescript Tailwind Sample*/}
      {/*</h1>*/}

        <BrowserRouter>
           <Routes>
                    {/*<Route path="/auth/:authType?" element={ <Auth/> } />*/}
               <Route path="/login" element={ <LoginScreen/> } />
               <Route path="/register" element={ <SignupScreen/> } />
               <Route element={ <BaseLayout /> }>

                   {/*<Route path="/" element={ <Navigate to="/dashboard" replace={true} /> } />*/}

                   <Route path="/" element={ <GuestHome/> } />
                   {/*<Route path="/dashboard"  element={*/}
                   {/*    localStorage.getItem('accessToken') ? <Home /> : <Navigate to="/login" />} />*/}
                   {/*<Route path="/restaurant/:id"  element={*/}
                   {/*    localStorage.getItem('accessToken') ? <RestaurantHome /> : <Navigate to="/login" />} />*/}
                   {/*<Route path="/favourites"  element={*/}
                   {/*    localStorage.getItem('accessToken') ? <Favourites /> : <Navigate to="/login" />} />*/}
                   {/*<Route path="/checkout"  element={*/}
                   {/*    localStorage.getItem('accessToken') ? <Checkout /> : <Navigate to="/login" />} />*/}

                   <Route path="/restaurants" element={ <Home/> } />
                   <Route path="/restaurant/:id" element={ <RestaurantHome/> } />
                   <Route path="/favourites" element={ <Favourites/> } />
                   <Route path="/checkout" element={ <Checkout/> } />
                   <Route path="/order-success" element={ <OrderSuccess/> } />
                   <Route path="/order-failed" element={ <OrderFailure/> } />
                   <Route path="/order-track" element={ <TrackOrder/> } />
                   <Route path="/edit-profile" element={ <EditProfile/> } />
                   <Route path="/privacy-policy" element={ <PrivacyPolicy/> } />

                   

                    </Route>
                </Routes>
        </BrowserRouter>

    </div>
  );
}

export default App;
