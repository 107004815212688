import {
    deleteRequest,
    getRequest, patchRequest, postFormDataRequest,
    postRequest
} from "../methodCalls";
import {deleteUserAddressURI, getUserAddressListURI, saveUserAddressURI, updateUserAddressURI} from "../endpoints";



export const getUserAddressList = (params) => {
    return getRequest(`${getUserAddressListURI}`);
}

export const saveUserAddress = (payload) => {
    return postFormDataRequest(saveUserAddressURI, payload);
}

export const updateUserAddress = (payload) => {
    const id = payload.menu_id;
    const reqURI = updateUserAddressURI.replace(':id', id);
    return postFormDataRequest(reqURI, payload);
}

export const deleteUserAddress = (payload) => {
    const id = payload.id;
    const reqURI = deleteUserAddressURI.replace(':id', id);
    return deleteRequest(reqURI, payload);
}
