import {
    deleteRequest,
    getRequest, patchRequest, postFormDataRequest,
    postRequest
} from "../methodCalls";

import {getUserCartItemsURI,addItemToCartURI,updateCartItemURI,deleteCartItemURI} from "../endpoints";


export const getUserCartItems = (params) => {
    return getRequest(`${getUserCartItemsURI}`);
}


export const addItemToCart = (payload) => {
    return postFormDataRequest(addItemToCartURI, payload);
}

export const updateCartItem = (payload) => {
    const id = payload.id;
    const reqURI = updateCartItemURI.replace(':id', id);
    return postFormDataRequest(reqURI, payload);
}

export const deleteCartItem = (payload) => {
    const id = payload.id;
    const reqURI = deleteCartItemURI.replace(':id', id);
    return deleteRequest(reqURI);
}
