import {GrClose} from "react-icons/gr";
// import bgImg from "../assets/login.png"

export const BaseModal = (props:any) =>{
    const { children, handleClose, auth } = props;

    return(
        <div className={`w-[100vw] h-[100vh] max-w-[100%] max-h-[100%] ${auth? "bg-login" : "bg-[black]"} bg-opacity-50 fixed top-0 left-0 m-0 p-0  overflow`}>
            <div className="bg-[white] m-auto p-8 min-w-[33%] min-h-[260px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[999] rounded-2xl
            4xl:min-w-[30%] 3xl:min-w-[30%] 2xl:min-w-[30%] xl:min-w-[30%] 1xl:min-w-[30%]
            lg:min-w-[33%] md2:min-w-[33%] md:min-w-[40%] sm:min-w-[60%] xs:min-w-[85%]">
                {
                    handleClose &&
                    <div className="flex justify-end text-[14px] text-sec-grey">
                    <button onClick={handleClose}>
                        <GrClose/>
                    </button>
                </div>
                }
                {children}
            </div>
        </div>
    )
}
