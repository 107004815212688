import restaurant from "../assets/restaurant.png"
import {AiOutlineHeart, AiFillHeart} from "react-icons/ai";
import {HiOutlineClock} from "react-icons/hi";
import {useNavigate} from "react-router-dom";
import {AbstractCard} from "./AbstractCard";

export function RestaurantCard(props:any) {
    const {selected, id, name, address, type, cuisines, image, startingPrice, deliveryTime, menu, nonClickable} = props;
    const navigate = useNavigate();

    const handleRestaurantClick = () =>{
        const data = {
            id,
            name,
            address,
            type,
            cuisines,
            image,
            startingPrice,
            deliveryTime,
            menu: menu
        }
        navigate(`/restaurant/${id}`, {state: data});
    }

    return (

        <div onClick={nonClickable? ()=>{} : handleRestaurantClick}>
            <AbstractCard
                nonClickable={nonClickable}
                type={type}
                name={name}
                image={image}
                cuisines={cuisines}
                startingPrice={startingPrice}
                deliveryTime={deliveryTime}
            />
        </div>


        // <div className={`p-0 w-[250px] h-[260px] rounded-xl justify-items-center ${selected? "bg-primary" : "bg-white"}`}>
        //     <div className="rest-card-img 2xl:max-h-[9rem] xl:max-h-[8rem] 1xl:max-h-[7rem] lg:max-h-[6rem] md:max-h-[4.3rem] static">
        //         <img
        //         src={restaurant}
        //         />
        //         <div className="flex w-[100%] pr-4 justify-end relative 2xl:top-[-140px] xl:top-[-125px] 1xl:top-[-115px] lg:top-[-95px] md:top-[-68px] ">
        //             <AiFillHeart size={20} color={"grey"}/>
        //         </div>
        //     </div>
        //
        //     <div className="rest-card-content p-[0.6rem] 2xl:pt-[1rem] xl:pt-[1rem] lg:pt-[0.7rem] mr-[1px] border-2 border-t-0 border-primary rounded-bl-xl rounded-br-xl
        //                     2xl:h-[110px] xl:h-[110px] 1xl:h-[100px] lg:h-[90px] ">
        //         <div className="text-left 2xl:text-[18px] xl:text-[18px] 1xl:text-[16px] lg:text-[14px] md:text-[10px]
        //          font-[Gilroy-Semibold] text-primary">
        //             Thai Famous Cuisine
        //         </div>
        //         <div className="text-left font-[Gilroy] text-grey md:text-[6px] lg:text-[9px] 1xl:text-[12px] xl:text-[14px] 2xl:text-[14px]">
        //             North Indian . Indian . Pure Veg
        //         </div>
        //         <div className="flex flex-row items-center gap-2 w-[100%] mt-2">
        //             <HiOutlineClock color={"grey"} />
        //             <div className="text-left 2xl:text-[16px] xl:text-[16px] min-[1280px]:text-[14px] lg:text-[12px] md:text-[8px] font-[Gilroy] text-grey">
        //                 30-35 min
        //             </div>
        //             <div className="text-right 2xl:text-[18px] xl:text-[18px] 1xl:text-[16px] lg:text-[14px] md:text-[8px]
        //             font-[Gilroy-Semibold] text-primary relative 2xl:right-[-60px] xl:right-[-40px] 1xl:right-[-20px] min-[1280px]:right-[-30px] lg:right-[-6px]">
        //                 $12.50
        //             </div>
        //         </div>
        //     </div>
        // </div>
    );
}

