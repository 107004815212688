import {LoginForm} from "../components/LoginForm";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

export const LoginScreen = () =>{
    const navigate = useNavigate();

    useEffect(()=>{
        const token = localStorage.getItem("accessToken")
        const user = localStorage.getItem("user")
        if(token && user){
            console.log(JSON.parse(user));
            console.log(JSON.parse(user).user_status);
            if(JSON.parse(user).user_status == "Pending"){
                navigate('/edit_profile')                
            }
            else
            navigate('/dashboard')
        }
    },[])

    return(
        <div className="rest-home bg-[white] w-[100vw] p-0 pl-0 pr-0">
            {/*<div className="w-[85%] m-auto pt-[1rem]">*/}

               <LoginForm/>

            {/*</div>*/}
        </div>
    );
}
