import React, { useEffect, useState } from "react";
import NavbarUserCard from "./NavbarUserCard";
import { FiShoppingCart } from "react-icons/fi";
import "../index.css";
import { CartCard } from "./CartCard/CartCard";
import { getUserCartItems } from "../api/cart/cart";
import logo from "../assets/nav-logo.png";
import NavbarGuest from "./NavbarGuest";
import { getReverseGeocodingAddress } from "../api/misc/misc";
import { MdOutlineShoppingBag } from "react-icons/md";
import Marker from "./Marker";
import { Link } from "react-router-dom";

function Navbar() {
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [cartItems, setCartItems] = useState<any[]>([]);
  const [show, setShow] = useState(false);
  const [updateCart, setUpdateCart] = useState<any>(false);
  const [userLocation, setUserLocation] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const toggleShow = () => setShow(!show);

  useEffect(() => {
    if (!loading) {
      const timer = setTimeout(() => {
        getUserCartData();
        setLoading(false);
      }, 500);

      setLoading(true);

      return () => clearTimeout(timer);
    }
  }, [updateCart]);

  const getUserCartData = async () => {
    try {
      const res = await getUserCartItems();
      if (res?.data?.records) {
        setCartItems(res?.data?.records);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCartButton = () => {
    if (isCartOpen) {
      setTimeout(() => {
        setIsCartOpen(false);
      }, 300);
    } else {
      setLoading(true);
      getUserCartData();
      setIsCartOpen(!isCartOpen);
    }
  };

  useEffect(() => {
    window.navigator.geolocation.getCurrentPosition(function (pos) {
      getLocation({ lat: pos.coords.latitude, lng: pos.coords.longitude });
    });
  }, []);

  const getLocation = async (raw: any) => {
    const res = await getReverseGeocodingAddress(raw);
    // console.log("locatpion res ::" , res.data.results[0].formatted_address)
    setUserLocation(res?.data?.results[0]?.formatted_address);
  };
  // 0px 0px 3px 2px rgba(0,0,0,0.25);
  // 0px -3px 0px 0px rgba(0,0,0,0.25) inset
  // 0px 1px 3px 2px rgba(0,0,0,0.25)
  return (
    <>
      <div className=" pt-3 pb-3 bg-white z-[100] mb-[5px] nav-shadow relative">
        <div className="w-[90%] m-auto py-1 px-10 flex flex-row flex-wrap items-center">
          <div className="basis-[10%] w-[70%] text-left">
            <Link to="/restaurants">
              <img height={70} width={70} src={logo} />
            </Link>
          </div>

          <div
            className="xs:hidden sm:hidden md2:block md:block lg:block xl:block 1xl:block 2xl:block 3xl:block 4xl:block
            basis-[72%] w-[70%] text-center font-[Gilroy] text-[12px]"
          >
            {userLocation}
          </div>

          <div className="basis-[18%] flex-1 2xl:w-[10%] xl:w-[10%] 1xl:w-[10%] justify-end">
            <div
              className="flex flex-row items-center gap-2 xs:justify-end sm:justify-end md:justify-end md2:justify-end lg:justify-between"
            >
              <div className="2xl:basis-3/4 xl:basis-3/4 1xl:basis-[100%]">
                <NavbarUserCard />
              </div>

              <div className="basis-1/4">
                <div className="flex basis-1/2 justify-end">
                  {/*<div className="p-2 bg-primary-light rounded-md cursor-pointer"*/}
                  {/*     onClick={handleCartButton}*/}
                  {/*>*/}
                  {/*    <FiShoppingCart*/}
                  {/*        size={20} color={"#fa4f26"} />*/}
                  {/*</div>*/}
                  {cartItems.length > 0 ? (
                    <>
                      <div
                        className="cursor-pointer p-3 rounded-md bg-primary h-[100%] "
                        onClick={handleCartButton}
                      >
                        <FiShoppingCart
                          // size={30} color={"#fa4f26"} />
                          size={25}
                          color={"#ffffff"}
                        />
                      </div>
                      <div className=" relative top-[-3px] left-[-15px]">
                        <Marker id="1" backgroundColor="white" padding="3px" />
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="cursor-pointer p-2 rounded-md bg-primary h-[100%] "
                        onClick={handleCartButton}
                      >
                        <FiShoppingCart size={30} color={"#ffffff"} />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {isCartOpen && (
          <CartCard
            cartItems={cartItems}
            updateCart={updateCart}
            setUpdateCart={setUpdateCart}
            handleClose={handleCartButton}
          />
        )}
      </div>

      {userLocation && (
        <div className=" pt-2 pb-2 mt-3 mb-3 bg-white xs:block sm:block md2:hidden md:hidden lg:hidden xl:hidden 1xl:hidden 2xl:hidden 3xl:hidden 4xl:hidden">
          <div
            className="m-auto  flex flex-row items-center pt-2 xs:block sm:block md2:hidden md:hidden lg:hidden xl:hidden 1xl:hidden 2xl:hidden 3xl:hidden 4xl:hidden
        basis-[72%] w-[70%] text-center font-[Gilroy] text-[12px]"
          >
            {userLocation}
          </div>
        </div>
      )}
    </>
  );
}

export default Navbar;
