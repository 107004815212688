import React, { useState } from "react";

interface NotificationProps {
  message: string;
}

const Notification: React.FC<NotificationProps> = ({ message }) => {
  return (
    <div className="absolute top-4 right-4 bg-gray-100 p-2 rounded-md shadow-md">
      {message}
    </div>
  );
};

export default Notification;
