import React, { useEffect, useState } from "react";
import { GrClose } from "react-icons/gr";
import { CartItem } from "./CartItem";
import { AbstractCartCard } from "./AbstractCartCard";
import { RestaurantCard } from "./RestaurantCard";
import { useNavigate } from "react-router-dom";
import { ImSpinner3 } from "react-icons/im";
import { updateCartItem } from "../../api/cart/cart";


interface CartItemProps {
  id: string;
  quantity: number;
  menueId: string;
  userId: string;
  userVariants: any; 
  menuItem: any; 
  setUpdateItemId: React.Dispatch<React.SetStateAction<any>>;
  onUpdate: () => void;
}

interface CartCardProps {
  cartItems: any[]; 
  handleClose: () => void;
  updateCart: boolean;
  setUpdateCart: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CartCard: React.FC<CartCardProps> = ({
  cartItems,
  handleClose,
  updateCart,
  setUpdateCart,
}) => {
  const navigate = useNavigate();
  const [userCartItems, setUserCartItems] = useState<any[]>([]);
  const [updateItemId, setUpdateItemId] = useState<any>(null);
  const [subTotal, setSubTotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0.0);
  const [apiLoading, setApiLoading] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleOnUpdate = () => {
    setUpdateCart(!updateCart);
  };

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      setUserCartItems(cartItems);
    } else {
      let localCart: any = localStorage.getItem("u%c%");
      if (localCart) {
        localCart = JSON.parse(localCart);
        setUserCartItems(localCart);
      }
      setApiLoading(false);
    }
  }, [cartItems]);

  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      let localCart: any = localStorage.getItem("u%c%");
      if (localCart) {
        localCart = JSON.parse(localCart);
        let updated = localCart.map((n: any) => {
          if (n.id === updateItemId?.id) {
            n.quantity = updateItemId.quantity;
          }
          return n;
        });
        localStorage.setItem("u%c%", JSON.stringify(updated));
        setUserCartItems(updated);
        setApiLoading(false);
      }
    }
  }, [updateCart]);

  const handleCheckout = () => {
    handleClose();
    navigate("/checkout");
  };

  useEffect(() => {
    let total = 0;
    userCartItems.forEach((c: any) => {
      for (let i = 1; i <= c.quantity; i++) {
        total += c.restaurant_menue?.regular_price;
      }
    });
    setSubTotal(total);
    setTotalAmount(parseFloat(total.toString()) + parseFloat(tax.toString()));
  }, [userCartItems, tax]);

  useEffect(() => {
    sessionStorage.setItem("total", totalAmount.toString());
  }, [totalAmount]);

  useEffect(() => {
    sessionStorage.setItem("subTotal", subTotal.toString());
  }, [subTotal]);

  useEffect(() => {
    if (userCartItems.length > 0) {
      sessionStorage.setItem("rest", userCartItems[0]?.restaurant_menue?.restaurant_id);
    }
  }, [userCartItems]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="fixed top-0 -right-[36%] max-h-full w-full overflow-y-auto rounded-md bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg overflow-hidden w-96">
        <div className="flex justify-end px-4 py-2">
          <GrClose className="cursor-pointer" onClick={handleClose} />
        </div>
        <div className="px-4 py-2 flex flex-col gap-3 h-96 overflow-y-auto">
          {userCartItems.length > 0 ? (
            <RestaurantCard restId={userCartItems[0]?.restaurant_menue?.restaurant_id} />
          ) : apiLoading ? (
            <div className="grid grid-cols-1 mt-5 justify-items-center">
              <h3>No Item added yet!</h3>
            </div>
          ) : (
            <div className="font-[Gilroy] text-[14px] text-sec-grey">Your Cart is Empty</div>
          )}

          {userCartItems.map((c: any) => (
            <CartItem
              key={c.id}
              id={c.id}
              quantity={c.quantity}
              menueId={c.restaurant_menue_id}
              userId={c.user_id}
              userVariants={c.user_variants}
              menuItem={c.restaurant_menue}
              setUpdateItemId={setUpdateItemId}
              onUpdate={handleOnUpdate}
            />
          ))}
        </div>

        <div className="bg-primary py-4 px-6 flex flex-col gap-2 items-center">
          <div className="flex flex-row w-full justify-between items-start gap-1">
            <div className="font-[Gilroy] text-[16px] text-white">Subtotal</div>
            <div className="font-[Gilroy-Semibold] text-[16px] text-white">${subTotal}</div>
          </div>

          <div className="flex flex-row w-full justify-between items-start gap-1">
            <div className="font-[Gilroy] text-[16px] text-white">Tax & Fee</div>
            <div className="font-[Gilroy-Semibold] text-[16px] text-white">${parseFloat(tax.toString()).toFixed(2)}</div>
          </div>

          <div className="flex flex-row w-full justify-between items-start gap-1">
            <div className="font-[Gilroy-Semibold] text-[16px] text-white">Total</div>
            <div className="font-[Gilroy-Semibold] text-[16px] text-white">${totalAmount}</div>
          </div>

          <button
            className="font-[Gilroy-Semibold] text-[16px] text-primary rounded-md bg-white px-4 py-2"
            onClick={handleCheckout}
          >
            Checkout
          </button>
        </div>
      </div>
    </div>
  );
};
