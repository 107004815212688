import restaurant from "../assets/restaurant.png"
import {AiFillStar, AiOutlineStar, AiTwotoneStar, AiFillHeart} from "react-icons/ai";
import {HiOutlineClock} from "react-icons/hi";
import {AbstractCard} from "./AbstractCard";
import {deleteUserFavMenu, saveUserFavMenu} from "../api/favourite/favourite";

export function MenuCard(props:any) {
    const {selected, id, handleAddToCart, type, name, price, rating, totalRatings, cuisines, image, variants, isFav, updateRequest} = props;

    const handleAddToFav = async () => {
        if(isFav){
            await deleteUserFavMenu({id: id});
            updateRequest();
        }
        else
        await saveUserFavMenu({
            restaurant_menue_id: id
        })
    }

    return (

        <AbstractCard
        type="MENU"
        name={name? name : "Peri Peri Veg Pizza"}
        price={price? price : "120"}
        image={image}
        variants={variants}
        rating={rating? rating : "3.1"}
        totalRatings={totalRatings? totalRatings : "100"}
        cuisines={cuisines? cuisines : "Loaded with extra cheese, Double Paneer, Pineapple"}
        handleAddToCart={handleAddToCart}
        handleAddToFav={handleAddToFav}
        />
    );
}

